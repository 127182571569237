import React, { useEffect, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Molfile_To_ID_API } from '../../constant';
import { setLoading, setReagentsString, setShowReagentsModal } from '../reducer';
import { postData } from '../../apiHelper';


export default function ReagentsModal() {
    const marvinInstance = useRef(null);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.synthetic.isLoading);
    const step1Params = useSelector((state) => state.synthetic.step1Params);
    const handleClose = () => dispatch(setShowReagentsModal(false));

    useEffect(() => {
        window.MarvinJSUtil.getPackage('#reagents-sketch').then(
            (marvinNameSpace) => {
                marvinInstance.current = marvinNameSpace.sketcherInstance;
            },
            (error) => {
                console.error(`Cannot retrieve marvin instance from iframe:${error}`);
            }
        );
    }, []);

    const add = () => {
        if (marvinInstance && marvinInstance.current) {
            let exportPromise = marvinInstance.current.exportStructure("mol", null);
            exportPromise.then(function (source) {
                if (source) {
                    dispatch(setLoading(true));
                    postData(Molfile_To_ID_API, {
                        molblock: source
                    }).then(
                        (result) => {
                            if (result && result.compID && result.compID !== "") {
                                var existing = [];
                                if (step1Params.reagents_trim.reagents_string.replace(/\s+/g, '') !== "") {
                                    existing = step1Params.reagents_trim.reagents_string.replace(/\s+/g, '').split(',');
                                }
                                existing.push(result.compID);
                                dispatch(setReagentsString(existing.join(',')));
                                dispatch(setShowReagentsModal(false));
                            } else {
                                alert("Sorry cannot search route of molecules that not exist in our database. Please try another one. Or double check the molecular structure and charges make sure it is a valid molecule.");
                            }
                            dispatch(setLoading(false));
                        },
                        (error) => {
                            console.log(error);
                            alert("Server error, please try again!");
                            dispatch(setLoading(false));
                        }
                    );
                }
            }, function (error) {
                alert(error);
            });
        }
    }

    return (
        <div>
            <div className="fade modal-backdrop show"></div>
            <div className='cdi-modal'>
                <div className='cdi-modal-dialog target'>
                    <div className='cdi-modal-content'>
                        <div className='cdi-modal-title'>
                            Add to reagents
                        </div>
                        <hr style={{ marginBottom: 30 + 'px' }} />
                        <div>
                            <div style={{ marginBottom: 10 + 'px' }} >
                                Draw molecules here:
                            </div>
                            <iframe id="reagents-sketch" title="reagents-sketch"
                                src={process.env.PUBLIC_URL + "/marvinjs/editor.html"}
                                className="reagents-marvin" />
                        </div>
                        <div className='footer'>
                            <hr style={{ marginBottom: 30 + 'px' }} />
                            <Button variant="secondary" onClick={handleClose} style={{ marginRight: 20 + 'px' }} disabled={isLoading}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={add} disabled={isLoading}>
                                {isLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: 5 + 'px' }}
                                    />
                                }
                                Add to reagents
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}