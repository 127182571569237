import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, DropdownButton, Dropdown, Spinner } from 'react-bootstrap';
import { BsArrowDownSquare } from 'react-icons/bs';
import { setShowFragModal, setFragType, setFragAction, addFragment, setLoading, setFrag } from '../reducer';
import { Atom_To_Smarts } from '../../constant';
import { postData } from '../../apiHelper';


function Params() {
    const dispatch = useDispatch();
    const step1Params = useSelector((state) => state.synthetic.step1Params);

    return (
        <>
            {step1Params.frag !== null &&
                <div className='frag-params'>
                    To
                    <DropdownButton title={step1Params.fragAction}>
                        <Dropdown.Item onClick={() => dispatch(setFragAction('keep'))}>keep</Dropdown.Item>
                        <Dropdown.Item onClick={() => dispatch(setFragAction('remove'))}>remove</Dropdown.Item>
                    </DropdownButton>
                    reactions contain
                    <DropdownButton title={step1Params.fragType}>
                        <Dropdown.Item onClick={() => dispatch(setFragType('any compounds'))}>any</Dropdown.Item>
                        <Dropdown.Item onClick={() => dispatch(setFragType('main compounds'))}>main</Dropdown.Item>
                    </DropdownButton>
                    molecule bearing above fragment.
                </div>
            }
        </>
    );
}

function Footer() {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.synthetic.isLoading);
    const step1Params = useSelector((state) => state.synthetic.step1Params);
    const handleClose = () => dispatch(setShowFragModal(false));

    function addToFragment() {
        dispatch(addFragment(step1Params.frag));
        dispatch(setFrag(null));
        dispatch(setShowFragModal(false));
    }

    return (
        <div className='footer'>
            <hr style={{ marginBottom: 30 + 'px' }} />
            <Button variant="secondary" onClick={handleClose} style={{ marginRight: 20 + 'px' }}>
                Close
            </Button>
            <Button variant="primary" onClick={addToFragment} disabled={isLoading}>
                {isLoading &&
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: 5 + 'px' }}
                    />
                }
                Add to Fragment List
            </Button>
        </div>
    );
}


export default function FragModal() {
    const marvinInstance = useRef(null);
    const dispatch = useDispatch();
    const step1Params = useSelector((state) => state.synthetic.step1Params);

    useEffect(() => {
        window.MarvinJSUtil.getPackage('#fragment-sketch').then(
            (marvinNameSpace) => {
                marvinInstance.current = marvinNameSpace.sketcherInstance;
            },
            (error) => {
                console.error(`Cannot retrieve marvin instance from iframe:${error}`);
            }
        );
    }, []);

    const getAtoms = () => {
        if (marvinInstance && marvinInstance.current) {
            let selection = marvinInstance.current.getSelection();
            let atoms = selection['atoms'];

            if (atoms) {
                let exportPromise = marvinInstance.current.exportStructure("mol", null);
                exportPromise.then(function (source) {
                    if (source) {
                        dispatch(setLoading(true));
                        var atomsList = atoms.replace(/ /g, '').split(',').map(function (item) {
                            return parseInt(item);
                        });

                        postData(Atom_To_Smarts, {
                            molblock: source,
                            atomIDs: atomsList
                        }).then(
                            (result) => {
                                if (result !== null && result.message != null) {
                                    alert(result.message);
                                    dispatch(setLoading(false));
                                } else if (result !== null && result.message == null) {
                                    dispatch(setFrag(result));
                                    dispatch(setLoading(false));
                                } else {
                                    alert("Error! Please try again!");
                                    dispatch(setLoading(false));
                                }
                            },
                            (error) => {
                                alert("Error! Please try again!");
                                console.log(error);
                                dispatch(setLoading(false));
                            }
                        );
                    }
                }, function (error) {
                    alert(error);
                });
            } else {
                alert("Please hold 'Shift' to select atoms");
            }
        }
    };

    return (
        <>
            <div className="fade modal-backdrop show"></div>
            <div className='cdi-modal'>
                <div className='cdi-modal-dialog'>
                    <div className='cdi-modal-content frag-modal'>
                        <div className='cdi-modal-title'>
                            Specify Fragments
                        </div>
                        <hr style={{ marginBottom: 30 + 'px' }} />
                        <div style={{ marginBottom: 10 + 'px' }} >
                            Draw molecules here and hold "Shift" to select atoms. NOTE: Please do not include hydrogen in selection of atoms in fragment.
                        </div>
                        <div>
                            <iframe id="fragment-sketch" title="fragment-sketch"
                                src={process.env.PUBLIC_URL + "/marvinjs/editor.html"}
                                className="fragment-marvin" />
                        </div>
                        <div className='get-labled-mol'>
                            <Button onClick={getAtoms} variant="primary">
                                <BsArrowDownSquare />
                            </Button>
                        </div>
                        {step1Params.frag !== null &&
                            <div className='frag-pic'>
                                <img src={"data:image/png;base64," + step1Params.frag.frag_pil} alt="" />
                            </div>
                        }
                        <Params />
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}