import React from 'react';
import './footer.css';

const footer = () => {
    return (
        <div className="footer">
          Copyright © 2024 CDI
        </div>
    )
}

export default footer