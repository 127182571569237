import React, { useState } from 'react';
import { Button, Spinner, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Search_API, Show_Similar_Mols } from '../../constant';
import { setLoading, addFeedstock, setShowSearchFeedstock } from '../reducer';
import { postData, postSearchData, getSearchData } from '../../apiHelper';


export default function SearchFeedstockModal() {
    const dispatch = useDispatch();
    const [searchId, setSearchId] = useState('');
    const [searchStatus, setSearchStatus] = useState('Not Started');
    const [similarMols, setSimilarMols] = useState([]);
    const isLoading = useSelector((state) => state.synthetic.isLoading);
    const step1Params = useSelector((state) => state.synthetic.step1Params);
    const searchFeedstockImg = useSelector((state) => state.synthetic.searchFeedstockImg);
    const handleClose = () => dispatch(setShowSearchFeedstock(false));

    const add = (mol) => {
        dispatch(addFeedstock(mol));
        handleClose();
    }

    const updateSearch = (searchIdNew) => {
        const checkSearchStatus = setInterval(() => {
            getSearchData(Search_API + "/" + searchIdNew).then(
                (searchData) => {
                    console.log(searchData);
                    if (searchData) {
                        switch (searchData.status) {
                            case "PENDING":
                                setSearchStatus("Search is pending");
                                break;
                            case "IN_PROGRESS":
                                if (searchData.progress && searchData.progress.message) {
                                    let message = searchData.progress.message;
                                    setSearchStatus(message);
                                    if (message.includes('No') || message.includes('stopped') || message.includes('exited')) {
                                        dispatch(setLoading(false));
                                        clearInterval(checkSearchStatus);
                                    }
                                } else {
                                    setSearchStatus("Search is in progress");
                                }
                                break;
                            case "COMPLETE":
                                if (searchData.progress && searchData.progress.message) {
                                    setSearchStatus(searchData.progress.message);
                                } else {
                                    setSearchStatus("Search completed, please click below button to see the results");
                                }
                                dispatch(setLoading(false));
                                clearInterval(checkSearchStatus);
                                break;
                            case "ERROR":
                                setSearchStatus("Searching is stopped and no useful information can be found. Please try other molecules or different set of parameters.");
                                dispatch(setLoading(false));
                                clearInterval(checkSearchStatus);
                                break;
                            default:
                                setSearchStatus("Searching is stopped and no useful information can be found. Please try other molecules or different set of parameters.");
                                dispatch(setLoading(false));
                                clearInterval(checkSearchStatus);
                                break;
                        }
                    }
                },
                (error) => {
                    console.log(error);
                    dispatch(setLoading(false));
                    clearInterval(checkSearchStatus);
                }
            );
        }, 5000);
    }

    const startSearch = () => {
        let request = {
            search_type: 'simis',
            search_params: {
                "target molecules": [step1Params.targetMol],
                "molecule parameters": {
                    "similarity threshold": 0.6,
                    "size difference": 3,
                    "thresh_simi_centre": 0.4
                },
                "compile_results": {
                    "top_n_mols": 5
                }
            },

        };

        dispatch(setLoading(true));
        setSearchId('');
        setSimilarMols([]);

        postSearchData(Search_API, request).then(
            (result) => {
                if (result && result.message === "Created") {
                    if (result.searchId) {
                        setSearchId(result.searchId);
                        updateSearch(result.searchId);
                    }
                } else {
                    dispatch(setLoading(false));
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );
    }

    const show = () => {
        setSimilarMols([]);

        postData(Show_Similar_Mols).then(
            (result) => {
                console.log(result);
                if (result && result.compID && result.compID.length > 0) {
                    var mols = [];
                    for (let i = 0; i < result.compID.length; i++) {
                        mols.push({
                            compID: result.compID[i],
                            mol_img: result.mol_img[i]
                        });
                    }
                    setSimilarMols(mols);
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );
    }

    return (
        <div>
            <div className="fade modal-backdrop show"></div>
            <div className='cdi-modal'>
                <div className='cdi-modal-dialog search-feedstock'>
                    <div className='cdi-modal-content'>
                        <div className='cdi-modal-title'>
                            Search similar molecules
                        </div>
                        <hr style={{ marginBottom: 30 + 'px' }} />
                        <div>
                            <div style={{ marginBottom: 10 + 'px' }} >
                                Below is the requested molecule, but it does not exist in our database, to search similar molecules, please click “Search Similar Molecules”.
                            </div>
                            <img src={"data:image/png;base64," + searchFeedstockImg} alt="" />
                            <div style={{ marginBottom: 10 + 'px' }} >
                                <Button variant="primary" onClick={startSearch} disabled={isLoading}>
                                    {isLoading &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            style={{ marginRight: 5 + 'px' }}
                                        />
                                    }
                                    Search Similar Molecules
                                </Button>
                            </div>
                            {searchStatus !== "Not Started" &&
                                <div className="description" style={{ marginTop: 20 + 'px' }}>
                                    {searchStatus}
                                </div>
                            }
                            <div>
                                <Button variant="warning" onClick={show} disabled={isLoading}>
                                    Show Similar Molecules
                                </Button>
                            </div>
                            {similarMols.length > 0 &&
                                <div className='description' style={{ marginTop: 20 + 'px' }}>
                                    Please select one molecule to continue Synthetic Route Search
                                </div>
                            }
                            <div className='similar-mol-box'>
                                <Row>
                                    {similarMols.map((t) =>
                                        <Col xs={6} key={t.compID.toString()}>
                                            <div className="similar-mol" onClick={() => add(t)}>
                                                <div style={{ marginBottom: 5 + 'px' }}>
                                                    {t.compID}
                                                </div>
                                                <img src={"data:image/png;base64," + t.mol_img} alt="" />
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </div>
                        <div className='footer'>
                            <hr style={{ marginBottom: 30 + 'px' }} />
                            <Button variant="secondary" onClick={handleClose} style={{ marginRight: 20 + 'px' }} disabled={isLoading}>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}