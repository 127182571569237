import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (state) => {
    let useridString = localStorage.getItem("userid");
    if (useridString) {
        localStorage.setItem(useridString + "-synthetic-params", JSON.stringify({
            isLoading: state.isLoading,
            currentStep: state.currentStep,
            searchStatus: state.searchStatus,
            searchId: state.searchId,
            step1Params: state.step1Params,
            step1Result: state.step1Result,
            step2Params: state.step2Params,
            step2Feedstocks: state.step2Feedstocks,
            showMoleResult: state.showMoleResult
        }));
    }
}

const initialState = {
    isLoading: false,
    currentStep: 1,
    searchStatus: "Not Started",
    searchId: "",
    step1Params: {
        showFragModal: false,
        frag: null,
        fragAction: 'remove',
        fragType: 'any compounds',
        keepFragments: [],
        dropFragments: [],
        targetType: "User Draw",
        targetMol: null,
        selectedTargets: [],
        selectedFeedstocks: [],
        yield_trim: '0.0',
        feedstock_type: "User Draw",
        feedMol: null,
        n_step_search: 1,
        solvents_trim: {
            action: "keep", type: "none", solvents_string: ""
        },
        reagents_trim: {
            action: "keep", type: "none", reagents_string: ""
        },
        reactants_action: "keep",
        reactants_string: "",
        products_action: "keep",
        products_string: "",
        similarity: {
            thresh: '0.5', fpType: "Independent"
        },
        remove_molecules_string: "",
        remove_reactions_string: "",
        set_yield: "No",
        heu_route_yield: {
            thresh: "10.0", n_steps_fail: "0"
        },
        set_SRI: "No",
        heu_route_SRI: {
            thresh: "0.8", n_steps_fail: "0"
        },
        rank_on: "Similarity",
        search_method: 1,
        search_speed: "fast",
        search_direction: "backward",
        showSolventsModal: false,
        showReagentsModal: false,
        showReactantsModal: false,
        showProductsModal: false
    },
    step1Result: null,
    step2Params: {
        rank_on: "Similarity",
        path_n: 2,
        length_options: [],
        reaction_per_page: 10,
        showMoleModal: false,
        mole_type: "Targets",
        top_n_mole: "5",
        mole_indices: "",
        mole_IDs: "",
        feeds_ID: "",
        feeds_ID_options: [],
        selected_feed_img: null
    },
    step2Routes: null,
    step2Feedstocks: null,
    showMoleResult: null,
    step2ReactionIds: "",
    step2ReactionDetails: null,
    showSearchTarget: false,
    showSearchFeedstock: false,
    searchTargetImg: null,
    searchFeedstockImg: null,
    showSummaryModal: false
};

export const syntheticSlice = createSlice({
    name: 'synthetic',
    initialState,
    reducers: {
        setState: (state, action) => {
            state.isLoading = action.payload.isLoading;
            state.currentStep = action.payload.currentStep;
            state.searchStatus = action.payload.searchStatus;
            state.searchId = action.payload.searchId;
            state.step1Params = action.payload.step1Params;
            state.step1Result = action.payload.step1Result;
            state.step2Params = action.payload.step2Params;
            state.step2Routes = action.payload.step2Routes;
            state.step2Feedstocks = action.payload.step2Feedstocks;
            state.showMoleResult = action.payload.showMoleResult;
        },
        setShowSummaryModal: (state, action) => {
            state.showSummaryModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep2ReactionIds: (state, action) => {
            state.step2ReactionIds = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep2ReactionDetails: (state, action) => {
            state.step2ReactionDetails = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowSearchTarget: (state, action) => {
            state.showSearchTarget = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowSearchFeedstock: (state, action) => {
            state.showSearchFeedstock = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSearchTargetImg: (state, action) => {
            state.searchTargetImg = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSearchFeedstockImg: (state, action) => {
            state.searchFeedstockImg = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSearchStatus: (state, action) => {
            state.searchStatus = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSearchId: (state, action) => {
            state.searchId = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSetFeedMol: (state, action) => {
            state.step1Params.feedMol = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSetTargetMol: (state, action) => {
            state.step1Params.targetMol = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSetSearchDirection: (state, action) => {
            state.step1Params.search_direction = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSetSearchSpeed: (state, action) => {
            state.step1Params.search_speed = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSetSearchMethod: (state, action) => {
            state.step1Params.search_method = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSetSRI: (state, action) => {
            state.step1Params.set_SRI = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSetYield: (state, action) => {
            state.step1Params.set_yield = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep2Feedstocks: (state, action) => {
            state.step2Feedstocks = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setReactionPerPage: (state, action) => {
            state.step2Params.reaction_per_page = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep2Routes: (state, action) => {
            state.step2Routes = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowMoleResult: (state, action) => {
            state.showMoleResult = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSelectedFeedImg: (state, action) => {
            state.step2Params.selected_feed_img = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMoleType: (state, action) => {
            state.step2Params.mole_type = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTopNMole: (state, action) => {
            state.step2Params.top_n_mole = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMoleIndeces: (state, action) => {
            state.step2Params.mole_indices = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMoleIDs: (state, action) => {
            state.step2Params.mole_IDs = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowMoleModal: (state, action) => {
            state.step2Params.showMoleModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowSolventsModal: (state, action) => {
            state.step1Params.showSolventsModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowReagentsModal: (state, action) => {
            state.step1Params.showReagentsModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowReactantsModal: (state, action) => {
            state.step1Params.showReactantsModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowProductsModal: (state, action) => {
            state.step1Params.showProductsModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setRoutesLength: (state, action) => {
            state.step2Params.path_n = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setRoutesLengthOptions: (state, action) => {
            state.step2Params.length_options = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFeedId: (state, action) => {
            state.step2Params.feeds_ID = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFeedIdOptions: (state, action) => {
            state.step2Params.feeds_ID_options = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setRankRoutes: (state, action) => {
            state.step2Params.rank_on = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setRankOn: (state, action) => {
            state.step1Params.rank_on = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setHeuYieldSteps: (state, action) => {
            state.step1Params.heu_route_yield.n_steps_fail = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setHeuYieldThresh: (state, action) => {
            state.step1Params.heu_route_yield.thresh = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setHeuSRIThresh: (state, action) => {
            state.step1Params.heu_route_SRI.thresh = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setHeuSRISteps: (state, action) => {
            state.step1Params.heu_route_SRI.n_steps_fail = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setReactionsString: (state, action) => {
            state.step1Params.remove_reactions_string = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMoleculesString: (state, action) => {
            state.step1Params.remove_molecules_string = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSimilarityThresh: (state, action) => {
            state.step1Params.similarity.thresh = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setReactantsString: (state, action) => {
            state.step1Params.reactants_string = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setProductsString: (state, action) => {
            state.step1Params.products_string = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSolventsString: (state, action) => {
            state.step1Params.solvents_trim.solvents_string = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setReagentsString: (state, action) => {
            state.step1Params.reagents_trim.reagents_string = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setReactantsAction: (state, action) => {
            state.step1Params.reactants_action = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setProductsAction: (state, action) => {
            state.step1Params.products_action = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSolventsAction: (state, action) => {
            state.step1Params.solvents_trim.action = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setReagentsAction: (state, action) => {
            state.step1Params.reagents_trim.action = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSolventsType: (state, action) => {
            state.step1Params.solvents_trim.type = action.payload;
            if (action.payload !== "others") {
                state.step1Params.solvents_trim.solvents_string = "";
            }

            // update localStorage
            updateLocalStorage(state);
        },
        setReagentsType: (state, action) => {
            state.step1Params.reagents_trim.type = action.payload;
            if (action.payload !== "others") {
                state.step1Params.reagents_trim.reagents_string = "";
            }

            // update localStorage
            updateLocalStorage(state);
        },
        setYieldTrim: (state, action) => {
            state.step1Params.yield_trim = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        addFragment: (state, action) => {
            var frag = action.payload;

            if (state.step1Params.fragAction === 'keep') {
                if (state.step1Params.keepFragments.length > 0) {
                    let existing = state.step1Params.keepFragments.map(function (item) {
                        return item.fragment;
                    });

                    if (!existing.includes(frag.smarts_frag)) {
                        let joined = state.step1Params.keepFragments.concat({
                            fragment: frag.smarts_frag,
                            frag_pil: frag.frag_pil,
                            action: state.step1Params.fragAction,
                            type: state.step1Params.fragType
                        });
                        state.step1Params.keepFragments = joined;
                    }
                } else {
                    let joined = state.step1Params.keepFragments.concat({
                        fragment: frag.smarts_frag,
                        frag_pil: frag.frag_pil,
                        action: state.step1Params.fragAction,
                        type: state.step1Params.fragType
                    });
                    state.step1Params.keepFragments = joined;
                }
            } else if (state.step1Params.fragAction === 'remove') {
                if (state.step1Params.dropFragments.length > 0) {
                    let existing = state.step1Params.dropFragments.map(function (item) {
                        return item.fragment;
                    });

                    if (!existing.includes(frag.smarts_frag)) {
                        let joined = state.step1Params.dropFragments.concat({
                            fragment: frag.smarts_frag,
                            frag_pil: frag.frag_pil,
                            action: state.step1Params.fragAction,
                            type: state.step1Params.fragType
                        });
                        state.step1Params.dropFragments = joined;
                    }
                } else {
                    let joined = state.step1Params.dropFragments.concat({
                        fragment: frag.smarts_frag,
                        frag_pil: frag.frag_pil,
                        action: state.step1Params.fragAction,
                        type: state.step1Params.fragType
                    });
                    state.step1Params.dropFragments = joined;
                }
            }

            // update localStorage
            updateLocalStorage(state);
        },
        removeFragment: (state, action) => {
            var frag = action.payload;

            if (frag.action === 'keep') {
                let array = [...state.step1Params.keepFragments].filter(function (item) {
                    return item.fragment !== frag.fragment;
                });
                state.step1Params.keepFragments = array;
            } else if (frag.action === 'remove') {
                let array = [...state.step1Params.dropFragments].filter(function (item) {
                    return item.fragment !== frag.fragment;
                });
                state.step1Params.dropFragments = array;
            }

            // update localStorage
            updateLocalStorage(state);
        },
        setFrag: (state, action) => {
            state.step1Params.frag = action.payload;

            // update localStorage
            let useridString = localStorage.getItem("userid");
            if (useridString) {
                localStorage.setItem(useridString + "-synthetic-params", JSON.stringify(state));
            }
        },
        setFragType: (state, action) => {
            state.step1Params.fragType = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFragAction: (state, action) => {
            state.step1Params.fragAction = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowFragModal: (state, action) => {
            state.step1Params.showFragModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep1Result: (state, action) => {
            state.step1Result = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep3Result: (state, action) => {
            state.step3Result = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetType: (state, action) => {
            state.step1Params.targetType = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFeedstockType: (state, action) => {
            state.step1Params.feedstock_type = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setNStepsSearch: (state, action) => {
            state.step1Params.n_step_search = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        addTarget: (state, action) => {
            var target = action.payload;

            if (state.step1Params.selectedTargets.length > 0) {
                var existing = state.step1Params.selectedTargets.map(function (item) {
                    return item.compID;
                });

                if (!existing.includes(target.compID)) {
                    let joined = state.step1Params.selectedTargets.concat(target);
                    state.step1Params.selectedTargets = joined;
                }
            } else {
                let joined = state.step1Params.selectedTargets.concat(target);
                state.step1Params.selectedTargets = joined;
            }

            // update localStorage
            updateLocalStorage(state);
        },
        removeTarget: (state, action) => {
            var target = action.payload;
            var array = [...state.step1Params.selectedTargets].filter(function (item) {
                return item.compID !== target.compID;
            });
            state.step1Params.selectedTargets = array;

            // update localStorage
            updateLocalStorage(state);
        },
        addFeedstock: (state, action) => {
            var feedstock = action.payload;
            if (state.step1Params.selectedFeedstocks.length > 0) {
                var existing = state.step1Params.selectedFeedstocks.map(function (item) {
                    return item.compID;
                });

                if (!existing.includes(feedstock.compID)) {
                    let joined = state.step1Params.selectedFeedstocks.concat(feedstock);
                    state.step1Params.selectedFeedstocks = joined;
                }
            } else {
                let joined = state.step1Params.selectedFeedstocks.concat(feedstock);
                state.step1Params.selectedFeedstocks = joined;
            }

            // update localStorage
            updateLocalStorage(state);
        },
        removeFeedstock: (state, action) => {
            var feedstock = action.payload;
            var array = [...state.step1Params.selectedFeedstocks].filter(function (item) {
                return item.compID !== feedstock.compID;
            });
            state.step1Params.selectedFeedstocks = array;

            // update localStorage
            updateLocalStorage(state);
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        nextStep: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            if (state.currentStep < 5) {
                state.currentStep += 1;
            }

            // update localStorage
            updateLocalStorage(state);
        },
        prevStep: (state) => {
            if (state.currentStep > 1) {
                state.currentStep -= 1;
            }

            // update localStorage
            updateLocalStorage(state);
        },
        setStep: (state, action) => {
            state.currentStep = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        resetState: () => {
            // update localStorage
            updateLocalStorage(initialState);

            return initialState;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setState, nextStep, prevStep, setStep, setStep2Result, setStep3Result, setShowMoleResult, setStep2ReactionDetails,
    setLoading, setStep2Routes, setTargetType, setSetSearchMethod, setRoutesLength, setFeedId, setSearchFeedstockImg,
    addTarget, removeTarget, addFeedstock, removeFeedstock, setMoleType, setFrag, setRoutesLengthOptions,
    setNStepsSearch, setFeedstockType, setStep1Result, setMoleIndeces, setSearchStatus, setFeedIdOptions,
    setShowFragModal, setShowMoleModal, setSetSearchSpeed, setSetSearchDirection, setRankRoutes, setShowSummaryModal,
    setFragType, setFragAction, addFragment, removeFragment, setMoleIDs, setYieldTrim, setSolventsType,
    setSimilarityThresh, setSolventsAction, setReagentsAction, setTopNMole, setSearchId, setReagentsType,
    setSolventsString, setReagentsString, setReactantsString, setProductsString, setSetSRI, setShowSearchTarget,
    setReactantsAction, setProductsAction, setReactionsString, setMoleculesString, setSetYield, setSearchTargetImg,
    setHeuYieldSteps, setHeuYieldThresh, setHeuSRIThresh, setHeuSRISteps, setRankOn, setSelectedFeedImg,
    setShowSolventsModal, setShowReagentsModal, setShowReactantsModal, setShowProductsModal, setShowSearchFeedstock,
    setStep2Feedstocks, setReactionPerPage, resetState, setSetFeedMol, setSetTargetMol, setStep2ReactionIds
} = syntheticSlice.actions;

export default syntheticSlice.reducer;