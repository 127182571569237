import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, DropdownButton, Dropdown, Spinner, Row, Col, Pagination } from 'react-bootstrap';
import {
    setStep2Routes, setRoutesLength, setLoading, setReactionPerPage, setShowMoleModal, setStep, setStep2ReactionIds, setStep2ReactionDetails,
    setShowSummaryModal
} from './reducer';
import { postData } from '../apiHelper';
import { Analogue_Show_Routes } from '../constant';
import MoleModal from './modals/mole-modal';
import SummaryModal from './modals/summary-modal';


export default function Result() {
    const dispatch = useDispatch();
    const reactionPerPageOptions = [5, 10, 15, 20];
    const step1Result = useSelector((state) => state.analogue.step1Result);
    const step2Params = useSelector((state) => state.analogue.step2Params);
    const step2Routes = useSelector((state) => state.analogue.step2Routes);
    const step2ReactionIds = useSelector((state) => state.analogue.step2ReactionIds);
    const step2ReactionDetails = useSelector((state) => state.analogue.step2ReactionDetails);
    const isLoading = useSelector((state) => state.analogue.isLoading);
    const showSummaryModal = useSelector((state) => state.analogue.showSummaryModal);
    const [activePage, setActivePage] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getImage = (id) => {
        return step2Routes.mol_imgs[id];
    }

    const getReactionIDs = (reaction) => {
        var result = "";

        for (var key in reaction.tags_rxns) {
            if (reaction.tags_rxns.hasOwnProperty(key) && key === "rxnIDs") {
                result = result + reaction.tags_rxns[key].join(', ');
            }
        }

        return result;
    }

    const viewMolecules = () => {
        setTimeout(() => dispatch(setShowMoleModal(true)), 1000);
    }

    const viewSummary = () => {
        setTimeout(() => dispatch(setShowSummaryModal(true)), 1000);
    }

    const goBack = () => {
        dispatch(setStep(1));
    }

    const refreshRoutes = () => {
        dispatch(setLoading(true));

        postData(Analogue_Show_Routes, {
            path_n: step2Params.path_n
        }).then(
            (routesData) => {
                if (routesData && routesData.routes) {
                    console.log(routesData);
                    dispatch(setStep2Routes(routesData));
                    dispatch(setLoading(false));
                } else {
                    alert("Server error, please try again!");
                    dispatch(setLoading(false));
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );
    }

    const updatePagination = (option) => {
        dispatch(setReactionPerPage(option));

        if (Math.ceil(step2Routes.routes.length / option) <= activePage) {
            setActivePage(Math.ceil(step2Routes.routes.length / option) - 1);
        }
    }

    const onStep2ReactionIdsChange = (event) => {
        var vaule = event.target.value;
        dispatch(setStep2ReactionIds(vaule));
    }

    const showReactionDetails = () => {
        dispatch(setStep2ReactionDetails(null));

        if (step2Routes && step2Routes.reaction_info) {
            var result = [];
            var reactionIds = step2ReactionIds.replace(/ /g, '').split(',');

            for (var key in step2Routes.reaction_info) {
                if (step2Routes.reaction_info.hasOwnProperty(key) && reactionIds.includes(key)) {
                    result.push({
                        reactionId: key,
                        reactionInfo: step2Routes.reaction_info[key]
                    });
                }
            }

            dispatch(setStep2ReactionDetails(result));
        }
    }

    return (
        <div className='search-result'>
            <div>
                <div className='title'>Summary of found synthetic routes:</div>
                {Object.keys(step1Result.params_analogue_route_search).length > 0 &&
                    <>
                        {Object.entries(step1Result.params_analogue_route_search)
                            .map(([key, value]) => <div key={key}>{key} {value.toString()}</div>)
                        }
                    </>
                }
            </div>

            <hr style={{ marginTop: 20 + 'px' }} />
            <div className='title'>Analogue synthetic routes</div>
            <div className='routes-length'>
                <span>Length of routes to display:</span>
                <DropdownButton title={step2Params.path_n}>
                    {step2Params.length_options.map((option) =>
                        <Dropdown.Item key={option.toString()}
                            onClick={() => dispatch(setRoutesLength(option))}>{option}</Dropdown.Item>
                    )}
                </DropdownButton>
            </div>

            <div className='refresh-routes'>
                <Button variant="warning" onClick={refreshRoutes} disabled={isLoading || step1Result == null}>
                    {isLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: 5 + 'px' }}
                        />
                    }
                    Refresh Analogue Synthetic Routes
                </Button>
            </div>

            <hr style={{ marginTop: 20 + 'px' }} />
            <div className='refresh-routes'>
                <Row>
                    <Col xs={6}>
                        <div className='title'>Please add reaction IDs below (separated by ","):</div>
                        <Form.Control type="text" value={step2ReactionIds} onChange={onStep2ReactionIdsChange} className='feedstocks-string' />
                        <Button variant="warning" onClick={showReactionDetails} disabled={isLoading}>
                            {isLoading &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: 5 + 'px' }}
                                />
                            }
                            Show Reaction Details
                        </Button>
                    </Col>
                    {step2ReactionDetails &&
                        <Col xs={6}>
                            <div className='reaction-details'>
                                {step2ReactionDetails.map((reaction, idx) =>
                                    <React.Fragment key={idx.toString()}>
                                        {idx !== 0 && <hr style={{ borderTop: 'dotted 1px' }} />}
                                        <div>Reaction ID: {reaction.reactionId}</div>
                                        {Object.entries(reaction.reactionInfo).map(([key, value]) => <div key={key}>{key}: {value.toString()}</div>)}
                                    </React.Fragment>
                                )}
                            </div>
                        </Col>
                    }
                </Row>
            </div>

            {step2Routes && step2Routes.routes && step2Routes.routes.length > 0 &&
                <div className='synth-routes'>
                    <div className='reactions-per-page'>
                        <span>Number of Reactions to display per page:</span>
                        <DropdownButton title={step2Params.reaction_per_page}>
                            {reactionPerPageOptions.map((option) =>
                                <Dropdown.Item key={option.toString()}
                                    onClick={() => updatePagination(option)}>{option}</Dropdown.Item>
                            )}
                        </DropdownButton>
                    </div>
                    <div className='reactions-pagination'>
                        <Pagination>
                            {[...Array(Math.ceil(step2Routes.routes.length / step2Params.reaction_per_page))].map((page, i) =>
                                <Pagination.Item active={i === activePage} key={i} onClick={() => setActivePage(i)}>
                                    {i + 1}
                                </Pagination.Item>
                            )}
                        </Pagination>
                    </div>
                    {step2Routes.routes
                        .slice(activePage * step2Params.reaction_per_page, (activePage + 1) * step2Params.reaction_per_page)
                        .map((route, idx1) =>
                            <div key={idx1.toString()} style={{ marginBottom: 30 + 'px' }} >
                                <div className='route-metrics'>
                                    <span>Route {idx1 + 1}:</span>
                                </div>
                                <Row >
                                    {route.reactions.map((reaction, idx2) =>
                                        <React.Fragment key={idx2.toString()}>
                                            {idx2 === 0 &&
                                                <>
                                                    <Col>
                                                        <img src={"data:image/png;base64," + getImage(reaction.rxt)} alt="" />
                                                        <div className='mole-id'>Molecule ID: {reaction.rxt.split(',')[0]}</div>
                                                    </Col>
                                                    <Col className='route-reaction'>
                                                        <div className='reaction-id'>
                                                            <span>Reaction IDs: {getReactionIDs(route.reactions[idx2])}</span>
                                                        </div>
                                                        <div>
                                                            <img src={process.env.PUBLIC_URL + '/images/arrow-right.png'} className='reaction-arrow' alt="" />
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <img src={"data:image/png;base64," + getImage(reaction.pro)} alt="" />
                                                        <div className='mole-id'>Molecule ID: {reaction.pro.split(',')[0]}</div>
                                                    </Col>
                                                </>
                                            }
                                            {idx2 !== 0 &&
                                                <>
                                                    <Col className='route-reaction'>
                                                        <div className='reaction-id'>
                                                            <span>Reaction IDs: {getReactionIDs(route.reactions[idx2])}</span>
                                                        </div>
                                                        <div>
                                                            <img src={process.env.PUBLIC_URL + '/images/arrow-right.png'} className='reaction-arrow' alt="" />
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <img src={"data:image/png;base64," + getImage(reaction.pro)} alt="" />
                                                        <div className='mole-id'>Molecule ID: {reaction.pro.split(',')[0]}</div>
                                                    </Col>
                                                </>
                                            }
                                        </React.Fragment>
                                    )}
                                </Row>
                            </div>
                        )}
                </div>
            }

            <hr style={{ marginTop: 20 + 'px' }} />
            <div className='title'>View molecules in the network</div>
            <div className='view-molecules'>
                <Button variant="warning" onClick={viewMolecules} disabled={isLoading || step1Result == null}>
                    {isLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: 5 + 'px' }}
                        />
                    }
                    View Molecules
                </Button>
            </div>

            <hr style={{ marginTop: 20 + 'px' }} />
            <div className='title'>Preview and download summary</div>
            <div className='view-summary'>
                <Button variant="warning" onClick={viewSummary} disabled={isLoading || step1Result == null}>
                    {isLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: 5 + 'px' }}
                        />
                    }
                    View Summary
                </Button>
            </div>

            <hr style={{ marginTop: 20 + 'px' }} />
            <div className='title'>Back to fine tune or rerun search</div>
            <div className='go-back'>
                <Button variant="warning" onClick={goBack} disabled={isLoading || step1Result == null}>
                    Go Back
                </Button>
            </div>

            {step2Params.showMoleModal ? <MoleModal /> : null}
            {showSummaryModal ? <SummaryModal /> : null}
        </div>
    );
}