import React, { useEffect, useRef } from 'react';
import { Button, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { BsArrowRightSquare, BsFillQuestionCircleFill } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { Atom_To_Smarts } from '../constant';
import { setLoading, setTargetMol, setCenterImg, setChiralCenter } from './reducer';
import { postData } from '../apiHelper';


export default function Target() {
    const marvinInstance = useRef(null);
    const dispatch = useDispatch();
    const center_img = useSelector((state) => state.chiral.center_img);
    const target_mol = useSelector((state) => state.chiral.target_mol);

    useEffect(() => {
        window.MarvinJSUtil.getPackage('#target-sketch').then(
            (marvinNameSpace) => {
                marvinInstance.current = marvinNameSpace.sketcherInstance;

                if (target_mol) {
                    let importPromise = marvinInstance.current.importStructure("MDL molfile", target_mol);
                    importPromise.then(function () { }, function (error) {
                        alert(error);
                    });
                }
            },
            (error) => {
                console.error(`Cannot retrieve marvin instance from iframe:${error}`);
            }
        );
    }, []);

    const selectCenter = () => {
        if (marvinInstance && marvinInstance.current) {
            let exportPromise = marvinInstance.current.exportStructure("mol", null);
            exportPromise.then(function (source) {
                if (source) {
                    dispatch(setLoading(true));
                    dispatch(setTargetMol(source));

                    let selection = marvinInstance.current.getSelection();
                    let atoms = selection['atoms'];

                    if (atoms) {
                        let exportPromise = marvinInstance.current.exportStructure("mol", null);
                        exportPromise.then(function (source) {
                            if (source) {
                                var atomsList = atoms.replace(/ /g, '').split(',').map(function (item) {
                                    return parseInt(item);
                                });

                                postData(Atom_To_Smarts, {
                                    molblock: source,
                                    atomIDs: atomsList
                                }).then(
                                    (result) => {
                                        if (result !== null) {
                                            dispatch(setCenterImg(result));
                                            dispatch(setChiralCenter(atomsList));
                                            dispatch(setLoading(false));
                                        }
                                    },
                                    (error) => {
                                        alert("Error! Please try again!");
                                        dispatch(setLoading(false));
                                    }
                                );
                            }
                        }, function (error) {
                            alert(error);
                            dispatch(setLoading(false));
                        });
                    } else {
                        alert("Please select atoms around the chiral center");
                        dispatch(setLoading(false));
                    }
                }
            }, function (error) {
                alert(error);
            });
        }
    };

    return (
        <div className="select-target">
            <div className='title'>
                Draw a chiral molecule to define the substructure of the chiral center
            </div>
            <div className='description'>
                <p>The substructure of the chiral center is defined by indices of atoms around the chiral center. </p>
                <div>
                    For more detailed explanation please click
                    <OverlayTrigger
                        key="fs-type"
                        placement="bottom"
                        overlay={
                            <Popover id="popover-contained">
                                <Popover.Header as="h3">Define Chiral Center</Popover.Header>
                                <Popover.Body>
                                    <p>
                                        <img src={process.env.PUBLIC_URL + '/images/chiral.png'} alt="" />
                                    </p>
                                    <p>
                                        Given above molecule as an example,  substructure of the chiral center can be defined by providing indices of atoms around the chiral center as follow: "6,7,8,9,10,12,32,33"
                                    </p>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <Button variant="success" className='tooltip-button'>
                            <BsFillQuestionCircleFill className='question' />
                        </Button>
                    </OverlayTrigger>
                </div>
            </div>
            <>
                <Row>
                    <Col xs={6}>
                        <iframe id="target-sketch" title="target-sketch"
                            src={process.env.PUBLIC_URL + "/marvinjs/editor.html"}
                            className="target-marvin" />
                    </Col>
                    <Col xs={2}>
                        <div className='select-btn'>
                            <div>
                                Highlight atoms of chiral substructure and click the arrow to select
                            </div>
                            <Button onClick={selectCenter} variant="primary">
                                <BsArrowRightSquare />
                            </Button>
                        </div>
                    </Col>
                    <Col xs={4} className='chiral-structure'>
                        {center_img &&
                            <div>
                                <img src={"data:image/png;base64," + center_img.frag_pil} alt="" />
                            </div>
                        }
                    </Col>
                </Row>
            </>
        </div>
    );
}