import React, { useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import './network.css';

export default function Network() {

  // This method is responsible for drawing the graph, returns the drawn network
  function drawGraph(currentNetwork) {
    var container = document.getElementById('mynetwork');

    // parsing and collecting nodes and edges from the python
    var nodes = new window.vis.DataSet(JSON.parse(JSON.stringify(currentNetwork.nodes)));
    var edges = new window.vis.DataSet(JSON.parse(JSON.stringify(currentNetwork.edges)));

    // adding nodes and edges to the graph
    var data = { nodes: nodes, edges: edges };

    var options = {
      "configure": {
        "enabled": false,
        "filter": [
          "physics",
          "selection",
          "renderer"
        ]
      },
      "edges": {
        "color": {
          "inherit": true
        },
        "smooth": {
          "enabled": true,
          "type": "dynamic"
        }
      },
      "interaction": {
        "dragNodes": true,
        "hideEdgesOnDrag": false,
        "hideNodesOnDrag": false
      },
      "physics": {
        "enabled": true,
        "stabilization": {
          "enabled": true,
          "fit": true,
          "iterations": 1000,
          "onlyDynamicEdges": false,
          "updateInterval": 50
        }
      }
    };

    var network = new window.vis.Network(container, data, options);

    return network;
  }

  useEffect(() => {
    let networkString = localStorage.getItem("network");

    if (networkString) {
      drawGraph(JSON.parse(networkString));
    }
  }, []);

  return (
    <div className='network'>
      <Container>
        <div className='title'>
          Reaction Networks
        </div>
        <div className='reaction-network'>
          <div className='description'>
            Vertices are molecules which are connected based on their reaction relationships. The arrow follows reaction directions. To see molecules in this network please click “View molecules in the network”.
          </div>
          <Card style={{ width: 100 + '%' }}>
            <Card.Body id="mynetwork"></Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
}