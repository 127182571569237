import { useSelector, useDispatch } from 'react-redux';
import { Accordion, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { setExplicitH, setChirality, setSimThresh, setAtomInRing } from './reducer';

const Params = () => {
    const dispatch = useDispatch();
    const step1Params = useSelector((state) => state.analogue.step1Params);

    const onSimThreshChange = (event) => {
        var value = event.target.value;
        dispatch(setSimThresh(value));
    }

    return (
        <>
            <div className='title'>
                Set similarity threshold
            </div>
            <div className='description'>
                The relevance and number of final results can be regulated by setting a similarity threshold. Highly similarity threshold improves the relevance but reduce number of results.
            </div>
            <div className='sim-thresh'>
                <span>Similarity threshold:  </span>
                <Form.Control type="number" value={step1Params.sim_thresh} onChange={onSimThreshChange} className='sim-thresh-value'
                    step="0.01" min="0" max="1" />
            </div>
            <div className='description'>
                Note that similarity threshold ranges between 0 and 1. Suggest select a value that is larger than 0.5.
            </div>
            <hr style={{ marginTop: 30 + 'px' }} />
            <div className='title'>
                Set option for hydrogen
            </div>
            <div className='description'>
                Explicit hydrogen attached to an atom. Set True, to differentiate atoms bearing different number of hydrogens. E.g., C=C and C-C will be differentiated. So that final results will be more focused and relevant. Choose False, if very few or no results can be found.
            </div>
            <div className='hydrogen'>
                <span>Consider explicit hydrogen: </span>
                <DropdownButton title={step1Params.explicit_H} className='hydrogen-dropdown'>
                    <Dropdown.Item onClick={() => dispatch(setExplicitH('True'))}>True</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setExplicitH('False'))}>False</Dropdown.Item>
                </DropdownButton>
            </div>
            <hr style={{ marginTop: 30 + 'px' }} />
            <div className='title'>
                If atom in a ring
            </div>
            <div className='atom'>
                <span>Consider if atom in a ring: </span>
                <DropdownButton title={step1Params.atom_in_ring} className='atom-dropdown'>
                    <Dropdown.Item onClick={() => dispatch(setAtomInRing('True'))}>True</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setAtomInRing('False'))}>False</Dropdown.Item>
                </DropdownButton>
            </div>
            <hr style={{ marginTop: 30 + 'px' }} />
            <div className='title'>
                Set option for chirality
            </div>
            <div className='chirality'>
                <span>Consider chirality: </span>
                <DropdownButton title={step1Params.chirality} className='chirality-dropdown'>
                    <Dropdown.Item onClick={() => dispatch(setChirality('True'))}>True</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setChirality('False'))}>False</Dropdown.Item>
                </DropdownButton>
            </div>
        </>
    );
}

export default function Advanced() {
    return (
        <div className='advanced'>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Advanced Settings</Accordion.Header>
                    <Accordion.Body>
                        <Params />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}