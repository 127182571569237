import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { setShowFragModal, removeFragment } from './reducer';


export default function Fragment() {
    const dispatch = useDispatch();
    const step1Params = useSelector((state) => state.synthetic.step1Params);

    return (
        <div className="fragment">
            <div className="title">Set preference on molecular fragment</div>
            <div className='description'>
                <p>
                    In this step, you can set preferences on Molecular Fragments (MF) as a searching heuristic. A molecular fragment is a substructure of a molecule. You can specify preferences on MF for two types of molecules:
                </p>
                <li>
                    Any molecules: all reactants and products of a reaction, including co-reactant (e.g., H2 in a reduction reaction) and byproducts (e.g., CO2 in an oxidative pyrolysis reaction).
                </li>
                <li>
                    Main molecules: the molecule contribute most atoms in a reaction.
                </li>
                <br />
                <p>
                    To “keep” preferred molecular fragment along the synthetic routes or to “remove” reactions involving undesired molecular fragment, e.g., benzene ring, C-Cl bond, or – F a fluorine atom. Please click ”Specify Fragments” for further instructions.
                </p>
            </div>
            <Button onClick={() => dispatch(setShowFragModal(true))} variant="primary">
                Specify Fragments
            </Button>
            {step1Params.dropFragments.length > 0 &&
                <div className='drop-frags'>
                    <div>
                        Reactions will be removed if they contain Any / Main molecules bearing following fragments:
                    </div>
                    <div style={{ marginBottom: 10 + 'px' }}>
                        (NOTE: Fragments shown here do not include peripheral atoms represented as *, as well as bonds attached to them.)
                    </div>
                    <Row>
                        {step1Params.dropFragments.map((fs) =>
                            <Col key={fs.fragment.toString()} xs={3} className="drop-frags-item">
                                <div style={{ marginBottom: 5 + 'px' }}>
                                    {fs.type.replace("compounds", "molecule")}
                                    <Button variant="danger" size="sm" style={{ marginLeft: 5 + 'px' }}
                                        onClick={() => dispatch(removeFragment(fs))}>
                                        X
                                    </Button>
                                </div>
                                <img src={"data:image/png;base64," + fs.frag_pil} alt="" />
                            </Col>
                        )}
                    </Row>
                </div>
            }
            {step1Params.keepFragments.length > 0 &&
                <div className='keep-frags'>
                    <div style={{ marginBottom: 10 + 'px' }}>
                        Reactions will be kept ONLY if they contain Any / Main molecules bearing following fragments:
                    </div>
                    <Row>
                        {step1Params.keepFragments.map((fs) =>
                            <Col key={fs.fragment.toString()} xs={3} className="keep-frags-item">
                                <div style={{ marginBottom: 5 + 'px' }}>
                                    {fs.type.replace("compounds", "molecule")}
                                    <Button variant="danger" size="sm" style={{ marginLeft: 5 + 'px' }}
                                        onClick={() => dispatch(removeFragment(fs))}>
                                        X
                                    </Button>
                                </div>
                                <img src={"data:image/png;base64," + fs.frag_pil} alt="" />
                            </Col>
                        )}
                    </Row>
                </div>
            }
        </div>
    );
}