import { configureStore } from '@reduxjs/toolkit';
import syntheticReducer from '../synthetic/reducer';
import analogueReducer from '../analogue/reducer';
import chiralReducer from '../chiral/reducer';
import userReducer from '../user/reducer';

export default configureStore({
    reducer: {
        synthetic: syntheticReducer,
        analogue: analogueReducer,
        chiral: chiralReducer,
        user: userReducer
    }
});