import React, { useEffect, useRef } from 'react';
import { Button, Row, Col, DropdownButton, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { BsArrowRightSquare, BsFillQuestionCircleFill } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { Molfile_To_ID_API } from '../constant';
import {
    setLoading, addFeedstock, removeFeedstock, setFeedstockType, setSetFeedMol,
    setSearchFeedstockImg, setShowSearchFeedstock
} from './reducer';
import { postData } from '../apiHelper';


export default function Feedstock() {
    const marvinInstance = useRef(null);
    const dispatch = useDispatch();
    const step1Params = useSelector((state) => state.synthetic.step1Params);

    useEffect(() => {
        window.MarvinJSUtil.getPackage('#feedstock-sketch').then(
            (marvinNameSpace) => {
                marvinInstance.current = marvinNameSpace.sketcherInstance;

                if (step1Params.feedMol) {
                    let importPromise = marvinInstance.current.importStructure("MDL molfile", step1Params.feedMol);
                    importPromise.then(function () { }, function (error) {
                        alert(error);
                    });
                }
            },
            (error) => {
                console.error(`Cannot retrieve marvin instance from iframe:${error}`);
            }
        );
    }, [step1Params.feedstock_type]);

    const selectFeedstock = () => {
        if (marvinInstance && marvinInstance.current) {
            let exportPromise = marvinInstance.current.exportStructure("mol", null);
            exportPromise.then(function (source) {
                if (source) {
                    dispatch(setLoading(true));
                    postData(Molfile_To_ID_API, {
                        molblock: source
                    }).then(
                        (result) => {
                            if (result && result.compID) {
                                dispatch(addFeedstock(result));
                                dispatch(setSetFeedMol(source));
                            } else if (result && result.mol_img && result.molblock) {
                                dispatch(setSearchFeedstockImg(result.mol_img));
                                dispatch(setSetFeedMol(source));
                                dispatch(setShowSearchFeedstock(true));
                            }
                            else {
                                alert("Molecule is no valid, please double check molecular structure and charges.");
                            }
                            dispatch(setLoading(false));
                        },
                        (error) => {
                            console.log(error);
                            dispatch(setLoading(false));
                        }
                    );
                }
            }, function (error) {
                alert(error);
            });
        }
    };

    return (
        <div className="select-feedstock">
            <div className='title'>
                Draw molecules or select type of molecules as feedstocks
            </div>
            <div className='description'>
                <p>
                    Select a feedstock type
                </p>
                <li>
                    If a feedstock type is selected, a set of molecules under this type will be used as feedstocks, so no need to provide specific feedstocks.&nbsp;
                    <OverlayTrigger
                        key="select-feedstock"
                        placement="top"
                        overlay={
                            <Popover id="popover-contained">
                                <Popover.Header as="h3">Feedstock Types</Popover.Header>
                                <Popover.Body>
                                    <p>
                                        <strong>Commercial:</strong> commercially available molecules.
                                    </p>
                                    <p>
                                        <strong>Renewables:</strong> a set of platform molecules from biomass including cellulose, lignin and chitin.
                                    </p>
                                    <p>
                                        <strong>Hubs:</strong> Hub molecules in big reaction network that are screened by using machine learning algorithms. These molecules are normally more available and lead to shorter synthetic routes.
                                    </p>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <Button variant="success" className='tooltip-button'>
                            <BsFillQuestionCircleFill className='question' />
                        </Button>
                    </OverlayTrigger>
                </li>
                <li>
                    If “User Draw” is selected, preferred feedstocks should be provided using the molecular drawer.
                </li>
            </div>
            <DropdownButton title={step1Params.feedstock_type} className='feedstock-type'>
                <Dropdown.Item onClick={() => dispatch(setFeedstockType('User Draw'))}>User Draw</Dropdown.Item>
                {step1Params.search_direction === "backward" &&
                    <>
                        <Dropdown.Item onClick={() => dispatch(setFeedstockType('Commercial'))}>Commercial</Dropdown.Item>
                        <Dropdown.Item onClick={() => dispatch(setFeedstockType('Renewable'))}>Renewable</Dropdown.Item>
                        <Dropdown.Item onClick={() => dispatch(setFeedstockType('Hub'))}>Hub</Dropdown.Item>
                    </>
                }
            </DropdownButton>
            {step1Params.feedstock_type === "User Draw" &&
                <>
                    <Row>
                        <Col xs={6}>
                            <iframe id="feedstock-sketch" title="feedstock-sketch"
                                src={process.env.PUBLIC_URL + "/marvinjs/editor.html"}
                                className="feedstock-marvin" />
                        </Col>
                        <Col xs={2}>
                            <div className='select-btn'>
                                <div>
                                    Select
                                </div>
                                <Button onClick={selectFeedstock} variant="primary">
                                    <BsArrowRightSquare />
                                </Button>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className='selected-feedstocks'>
                                <div style={{ marginBottom: 10 + 'px' }}>
                                    Selected starting molecules as feedstocks to synthesize from:
                                </div>
                                {step1Params.selectedFeedstocks.map((t) =>
                                    <div key={t.compID.toString()} className="selected-feedstock-item">
                                        <div style={{ marginBottom: 5 + 'px' }}>
                                            {t.compID}
                                            <Button variant="danger" size="sm" style={{ marginLeft: 5 + 'px' }}
                                                onClick={() => dispatch(removeFeedstock(t))}>
                                                X
                                            </Button>
                                        </div>
                                        <img src={"data:image/png;base64," + t.mol_img} alt="" />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </div>
    );
}