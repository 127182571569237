import React, { useEffect, useRef } from 'react';
import { Button, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import { BsArrowRightSquare } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { Molfile_To_ID_API } from '../constant';
import {
    setLoading, addTarget, removeTarget, setTargetType, setSetSearchDirection,
    setFeedstockType, setNStepsSearch, setSetTargetMol, setSearchTargetImg,
    setShowSearchTarget
} from './reducer';
import { postData } from '../apiHelper';


export default function Target() {
    const marvinInstance = useRef(null);
    const dispatch = useDispatch();
    const step1Params = useSelector((state) => state.synthetic.step1Params);

    useEffect(() => {
        window.MarvinJSUtil.getPackage('#target-sketch').then(
            (marvinNameSpace) => {
                marvinInstance.current = marvinNameSpace.sketcherInstance;

                if (step1Params.targetMol) {
                    let importPromise = marvinInstance.current.importStructure("MDL molfile", step1Params.targetMol);
                    importPromise.then(function () { }, function (error) {
                        alert(error);
                    });
                }
            },
            (error) => {
                console.error(`Cannot retrieve marvin instance from iframe:${error}`);
            }
        );
    }, [step1Params.targetType]);

    const selectTarget = () => {
        if (marvinInstance && marvinInstance.current) {
            let exportPromise = marvinInstance.current.exportStructure("mol", null);
            exportPromise.then(function (source) {
                if (source) {
                    dispatch(setLoading(true));
                    postData(Molfile_To_ID_API, {
                        molblock: source
                    }).then(
                        (result) => {
                            if (result && result.compID) {
                                dispatch(addTarget(result));
                                dispatch(setSetTargetMol(source));
                            } else if (result && result.mol_img && result.molblock) {
                                dispatch(setSearchTargetImg(result.mol_img));
                                dispatch(setSetTargetMol(source));
                                dispatch(setShowSearchTarget(true));
                            }
                            else {
                                alert("Molecule is no valid, please double check molecular structure and charges.");
                            }
                            dispatch(setLoading(false));
                        },
                        (error) => {
                            console.log(error);
                            dispatch(setLoading(false));
                        }
                    );
                }
            }, function (error) {
                alert(error);
            });
        }
    };

    const onDirectionChange = (value) => {
        if (value === "backward") {
            dispatch(setTargetType('User Draw'));
        } else if (value === "forward") {
            dispatch(setFeedstockType('User Draw'));

            if (step1Params.n_step_search > 5) {
                dispatch(setNStepsSearch(5));
            }
        }

        dispatch(setSetSearchDirection(value));
    }

    return (
        <div className="select-target">
            <div className='search-direction'>
                <span className='title'>Search Direction </span>
                <DropdownButton title={step1Params.search_direction} className='search-direction-dropdown'>
                    <Dropdown.Item onClick={() => onDirectionChange('backward')}>backward</Dropdown.Item>
                    <Dropdown.Item onClick={() => onDirectionChange('forward')}>forward</Dropdown.Item>
                </DropdownButton>
            </div>
            <hr style={{ marginTop: 30 + 'px' }} />
            <div className='title'>
                Draw molecules or select type of molecules to synthesis
            </div>
            <DropdownButton title={step1Params.targetType} className='target-type'>
                <Dropdown.Item onClick={() => dispatch(setTargetType('User Draw'))}>User Draw</Dropdown.Item>
                {step1Params.search_direction === "forward" &&
                    <>
                        <Dropdown.Item onClick={() => dispatch(setTargetType('Commercial'))}>Commercial</Dropdown.Item>
                        <Dropdown.Item onClick={() => dispatch(setTargetType('Hub'))}>Hub</Dropdown.Item>
                    </>
                }
            </DropdownButton>
            {step1Params.targetType === "User Draw" &&
                <>
                    <Row>
                        <Col xs={6}>
                            <iframe id="target-sketch" title="target-sketch"
                                src={process.env.PUBLIC_URL + "/marvinjs/editor.html"}
                                className="target-marvin" />
                        </Col>
                        <Col xs={2}>
                            <div className='select-btn'>
                                <div>
                                    Select
                                </div>
                                <Button onClick={selectTarget} variant="primary">
                                    <BsArrowRightSquare />
                                </Button>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className='selected-targets'>
                                <div style={{ marginBottom: 10 + 'px' }}>
                                    Target molecules you plan to synthesize:
                                </div>
                                {step1Params.selectedTargets.map((t) =>
                                    <div key={t.compID.toString()} className="selected-target-item">
                                        <div style={{ marginBottom: 5 + 'px' }}>
                                            {t.compID}
                                            <Button variant="danger" size="sm" style={{ marginLeft: 5 + 'px' }}
                                                onClick={() => dispatch(removeTarget(t))}>
                                                X
                                            </Button>
                                        </div>
                                        <img src={"data:image/png;base64," + t.mol_img} alt="" />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </div>
    );
}