import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (state) => {
    let useridString = localStorage.getItem("userid");
    if (useridString) {
        // store in localStorage except step2Routes because it's too big
        localStorage.setItem(useridString + "-chiral-params", JSON.stringify({
            currentStep: state.currentStep,
            isLoading: state.isLoading,
            searchStatus: state.searchStatus,
            searchId: state.searchId,
            explicit_H: state.explicit_H,
            aromaticity: state.aromaticity,
            atom_in_ring: state.atom_in_ring,
            target_mol: state.target_mol,
            center_img: state.center_img,
            chiral_center: state.chiral_center,
            sim_thresh: state.sim_thresh,
            step1Result: state.step1Result,
            step2Params: state.step2Params,
            showMoleResult: state.showMoleResult
        }));
    }
}

const initialState = {
    currentStep: 1,
    isLoading: false,
    searchStatus: "Not Started",
    searchId: "",
    explicit_H: "False",
    aromaticity: "True",
    atom_in_ring: "False",
    target_mol: null,
    center_img: null,
    chiral_center: null,
    sim_thresh: "0.6",
    step1Result: null,
    step2Params: {
        reaction_per_page: 10,
        showMoleModal: false,
        mole_IDs: ""
    },
    step2Routes: null,
    showMoleResult: null,
    step2ReactionIds: "",
    step2ReactionDetails: null,
    showSummaryModal: false
}

export const chiralSlice = createSlice({
    name: 'chiral',
    initialState,
    reducers: {
        setState: (state, action) => {
            state.isLoading = action.payload.isLoading;
            state.currentStep = action.payload.currentStep;
            state.searchStatus = action.payload.searchStatus;
            state.searchId = action.payload.searchId;
            state.explicit_H = action.payload.explicit_H;
            state.aromaticity = action.payload.aromaticity;
            state.atom_in_ring = action.payload.atom_in_ring;
            state.target_mol = action.payload.target_mol;
            state.center_img = action.payload.center_img;
            state.chiral_center = action.payload.chiral_center;
            state.sim_thresh = action.payload.sim_thresh;
            state.step1Result = action.payload.step1Result;
            state.step2Params = action.payload.step2Params;
            state.step2Routes = action.payload.step2Routes;
        },
        setShowSummaryModal: (state, action) => {
            state.showSummaryModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep2ReactionIds: (state, action) => {
            state.step2ReactionIds = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep2ReactionDetails: (state, action) => {
            state.step2ReactionDetails = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSearchStatus: (state, action) => {
            state.searchStatus = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMoleIDs: (state, action) => {
            state.step2Params.mole_IDs = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowMoleModal: (state, action) => {
            state.step2Params.showMoleModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowMoleResult: (state, action) => {
            state.showMoleResult = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSearchId: (state, action) => {
            state.searchId = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setChiralCenter: (state, action) => {
            state.chiral_center = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setCenterImg: (state, action) => {
            state.center_img = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetMol: (state, action) => {
            state.target_mol = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep1Result: (state, action) => {
            state.step1Result = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSimThresh: (state, action) => {
            state.sim_thresh = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep: (state, action) => {
            state.currentStep = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setExplicitH: (state, action) => {
            state.explicit_H = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setAromaticity: (state, action) => {
            state.aromaticity = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setAtomInRing: (state, action) => {
            state.atom_in_ring = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setReactionPerPage: (state, action) => {
            state.step2Params.reaction_per_page = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep2Routes: (state, action) => {
            state.step2Routes = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        resetState: () => {
            // update localStorage
            updateLocalStorage(initialState);

            return initialState;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setState, setLoading, setTargetMol, setStep1Result, setSimThresh, setCenterImg, setStep, setExplicitH, setShowSummaryModal,
    setAromaticity, setAtomInRing, setSearchId, setMoleIDs, setShowMoleModal, setShowMoleResult, setStep2ReactionDetails,
    setReactionPerPage, setStep2Routes, setChiralCenter, resetState, setSearchStatus, setStep2ReactionIds
} = chiralSlice.actions;

export default chiralSlice.reducer;