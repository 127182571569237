/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "chemrouterWebappAPIGW",
            "endpoint": "https://kxyuz4true.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_vFVPbl3eI",
    "aws_user_pools_web_client_id": "17uoeqvqng78u16lgmlibh5ibm",
    "oauth": {
        "domain": "auth.cdi-sg.com.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "chemrouter-prod.cdi-sg.com/api",
            "openid",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/,https://chemrouter.cdi-sg.com/",
        "redirectSignOut": "http://localhost:3000/,https://chemrouter.cdi-sg.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
