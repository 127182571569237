import { Auth } from 'aws-amplify';
import { InstantBaseUrl, SearchBaseUrl } from "./constant";

// POST Instant API
export async function postData(url = '', data = {}) {
    return Auth.currentSession().then(async res => {
        if (res && res.accessToken && res.accessToken.jwtToken) {
            // Default options are marked with *
            const response = await fetch(InstantBaseUrl + url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Authorization': 'Bearer ' + res.accessToken.jwtToken,
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            return null;
        }
    });
};

// GET Instant API
export async function getData(url = '') {
    return Auth.currentSession().then(async res => {
        if (res && res.accessToken && res.accessToken.jwtToken) {
            // Default options are marked with *
            const response = await fetch(InstantBaseUrl + url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Authorization': 'Bearer ' + res.accessToken.jwtToken,
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                }
            });
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            return null;
        }
    });
};

// POST Search API
export async function postSearchData(url = '', data = {}) {
    return Auth.currentSession().then(async res => {
        if (res && res.accessToken && res.accessToken.jwtToken) {
            // Default options are marked with *
            const response = await fetch(SearchBaseUrl + url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Authorization': 'Bearer ' + res.accessToken.jwtToken,
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            return null;
        }
    });
};

// GET Search API
export async function getSearchData(url = '') {
    return Auth.currentSession().then(async res => {
        if (res && res.accessToken && res.accessToken.jwtToken) {
            // Default options are marked with *
            const response = await fetch(SearchBaseUrl + url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Authorization': 'Bearer ' + res.accessToken.jwtToken,
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                }
            });
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            return null;
        }
    });
};