import { useSelector, useDispatch } from 'react-redux';
import { DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { setNStepsSearch, setYieldTrim } from './reducer';


export default function Params() {
    const dispatch = useDispatch();
    const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    const step1Params = useSelector((state) => state.synthetic.step1Params);

    const onYieldChange = (event) => {
        let yield_trim = event.target.value;

        if (parseFloat(yield_trim) < 0) {
            alert("Yield threshold must be between 0.0 and 100.0!");
            yield_trim = "0.0";
        }

        if (parseFloat(yield_trim) > 100) {
            alert("Yield threshold must be between 0.0 and 100.0!");
            yield_trim = "100.0";
        }

        dispatch(setYieldTrim(yield_trim));
    }

    const onStepChange = (value) => {
        if (value > 5 && step1Params.search_direction === "forward") {
            alert("Only backward direction is allowed for more than 5 steps");
            dispatch(setNStepsSearch(1));
        } else {
            dispatch(setNStepsSearch(value));
        }
    }

    return (
        <div className="params">
            <div className='title'>
                Number of synthetic steps
            </div>
            <div className='description'>
                <li>
                    All possible routes with lengths less than this number will be searched. For example, if you set 5, results will cover all possible routes with lengths of 1, 2, 3, 4 and 5.
                </li>
                <li>
                    If the number is larger than 5, long range search will be performed.
                </li>
            </div>
            <DropdownButton title={step1Params.n_step_search} className='n-steps'>
                {steps.map((n) =>
                    <Dropdown.Item onClick={() => onStepChange(n)} key={n}>
                        {n}
                    </Dropdown.Item>
                )}
            </DropdownButton>
            <div className='yield-thres'>
                <div className='title'>
                    Yield threshold for reactions
                </div>
                <span>Reactions with yields less than </span>
                <Form.Control type="number" value={step1Params.yield_trim} onChange={onYieldChange} className='yield-thres-value' />
                <span> % will be removed.</span>
            </div>
        </div>
    );
}