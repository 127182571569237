import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Button, Spinner } from 'react-bootstrap';
import {
    setState, resetState, setLoading, setStep1Result, setStep, setSearchId, setStep2Routes, setSearchStatus,
    setStep2ReactionIds, setStep2ReactionDetails
} from './reducer';
import './chiral.css';
import { } from '../constant';
import { postData, postSearchData, getSearchData } from '../apiHelper';
import { Search_API, Chiral_Show_Routes, See_Results_Chiral } from '../constant';
import Target from './target';
import Advanced from './advanced';
import Result from './result';


export default function Chiral() {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.chiral.isLoading);
    const currentStep = useSelector((state) => state.chiral.currentStep);
    const center_img = useSelector((state) => state.chiral.center_img);
    const target_mol = useSelector((state) => state.chiral.target_mol);
    const sim_thresh = useSelector((state) => state.chiral.sim_thresh);
    const chiral_center = useSelector((state) => state.chiral.chiral_center);
    const explicit_H = useSelector((state) => state.chiral.explicit_H);
    const aromaticity = useSelector((state) => state.chiral.aromaticity);
    const atom_in_ring = useSelector((state) => state.chiral.atom_in_ring);
    const step1Result = useSelector((state) => state.chiral.step1Result);
    const searchStatus = useSelector((state) => state.chiral.searchStatus);

    useEffect(() => {
        let useridString = localStorage.getItem("userid");

        if (useridString) {
            let params = localStorage.getItem(useridString + "-chiral-params");
            if (params) {
                let state = JSON.parse(params);
                dispatch(setState(state));

                if (state.isLoading && !state.searchId) {
                    startSearch();
                } else if (state.currentStep === 1 && state.searchId) {
                    updateSearch(state.searchId);
                }
                
                if (state.currentStep === 2) {
                    seeResults(); // load routes since the result is too big to store in localStorage
                }
            }
        }
    }, []);

    const reset = () => {
        dispatch(resetState());
    }

    const updateSearch = (searchId) => {
        const checkSearchStatus = setInterval(() => {
            getSearchData(Search_API + "/" + searchId).then(
                (searchData) => {
                    console.log(searchData);
                    if (searchData) {
                        switch (searchData.status) {
                            case "PENDING":
                                dispatch(setSearchStatus("Search is pending"));
                                break;
                            case "IN_PROGRESS":
                                if (searchData.progress && searchData.progress.message) {
                                    let message = searchData.progress.message;
                                    dispatch(setSearchStatus(message));
                                    if (message.includes('No') || message.includes('stopped') || message.includes('exited')) {
                                        dispatch(setLoading(false));
                                        clearInterval(checkSearchStatus);
                                    }
                                } else {
                                    dispatch(setSearchStatus("Search is in progress"));
                                }
                                break;
                            case "COMPLETE":
                                if (searchData.progress && searchData.progress.message) {
                                    dispatch(setSearchStatus(searchData.progress.message));
                                } else {
                                    dispatch(setSearchStatus("Search completed, please click below button to see the results"));
                                }
                                dispatch(setLoading(false));
                                dispatch(setStep1Result(searchData.result));
                                clearInterval(checkSearchStatus);
                                break;
                            case "ERROR":
                                dispatch(setSearchStatus("Searching is stopped and no useful information can be found. Please try other molecules or different set of parameters."));
                                dispatch(setLoading(false));
                                clearInterval(checkSearchStatus);
                                break;
                            default:
                                dispatch(setSearchStatus("Searching is stopped and no useful information can be found. Please try other molecules or different set of parameters."));
                                dispatch(setLoading(false));
                                clearInterval(checkSearchStatus);
                                break;
                        }
                    }
                },
                (error) => {
                    console.log(error);
                    dispatch(setLoading(false));
                    clearInterval(checkSearchStatus);
                }
            );
        }, 5000);
    }

    const startSearch = () => {
        if (!target_mol || !center_img || !chiral_center) {
            alert("Please specify a target and define the substructure of the chiral center!");
            return;
        }

        dispatch(setLoading(true));
        dispatch(setSearchStatus("Not Started"));
        dispatch(setSearchId(""));

        let request = {
            search_type: "chiral",
            search_params: {
                molblock: target_mol,
                chiral_center: chiral_center,
                explicity: {
                    degree: false,
                    ring: atom_in_ring === "True" ? true : false,
                    H_count: explicit_H === "True" ? true : false,
                    aromatic: aromaticity === "True" ? true : false
                },
                sim_thresh_chiral: {
                    sim_thresh: parseFloat(sim_thresh),
                    fp_type: "Independent"
                },
                chiral_comp_size_coeff: 2,
                sort_atm_idxs: true,
                molblock_type: "marvin",
                comps_pool_size_chiral: null
            }
        };

        postSearchData(Search_API, request).then(
            (result) => {
                if (result && result.message === "Created") {
                    dispatch(setStep1Result(null));

                    if (result.searchId) {
                        dispatch(setSearchId(result.searchId));
                        updateSearch(result.searchId);
                    }
                } else {
                    dispatch(setLoading(false));
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );
    }

    const seeResults = () => {
        dispatch(setLoading(true));

        if (currentStep === 1) {
            // Always show results based on default parameters when click: “See Results”
            dispatch(setStep2ReactionIds(""));
            dispatch(setStep2ReactionDetails(null));
        }

        postData(See_Results_Chiral).then(
            (result) => {
                if (result) {
                    postData(Chiral_Show_Routes, {
                        n_top_rxns: 200
                    }).then(
                        (routesData) => {
                            if (routesData) {
                                console.log(routesData);
                                dispatch(setStep2Routes(routesData));
                                dispatch(setStep(2));
                                dispatch(setLoading(false));
                            } else {
                                alert("Server error, please try again!");
                                dispatch(setLoading(false));
                            }
                        },
                        (error) => {
                            console.log(error);
                            dispatch(setLoading(false));
                        }
                    );
                } else {
                    alert("Server error, please try again!");
                    dispatch(setLoading(false));
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );
    }

    return (
        <div className='chiral'>
            <Container>
                <div className='title'>
                    Search Chiral Reactions

                    {currentStep === 1 &&
                        <div className='reset'>
                            <Button variant="warning" onClick={reset}>
                                Reset
                            </Button>
                        </div>
                    }
                </div>
                <div className='sub-title'>
                    {currentStep === 1 &&
                        <strong>Stage 1: Set up parameters and initiate search</strong>
                    }
                    {currentStep === 2 &&
                        <strong>Stage 2: View chiral results</strong>
                    }
                </div>
                {currentStep === 1 &&
                    <>
                        <div className='description'>
                            This application recommends asymmetric reactions can inspire your target asymmetric synthesis. These asymmetric reactions produce chiral products that have similar substructure around the chiral center of the target molecule.
                        </div>
                        <hr style={{ marginTop: 30 + 'px' }} />
                        <Target />
                        <hr style={{ marginTop: 30 + 'px' }} />
                        <div>
                            <Button onClick={startSearch} variant="primary" disabled={isLoading}>
                                {isLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: 5 + 'px' }}
                                    />
                                }
                                Start Chiral Reaction Search
                            </Button>
                        </div>
                        {searchStatus !== "Not Started" &&
                            <div className="description" style={{ marginTop: 20 + 'px' }}>
                                {searchStatus}
                            </div>
                        }
                        <div className='see-results'>
                            <Button variant="warning" onClick={seeResults} disabled={isLoading || step1Result == null}>
                                {isLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: 5 + 'px' }}
                                    />
                                }
                                See Results
                            </Button>
                        </div>
                        <Advanced />
                    </>
                }
                {currentStep === 2 &&
                    <Result />
                }
            </Container>
        </div>
    );
}