import { useSelector, useDispatch } from 'react-redux';
import { Accordion, DropdownButton, Dropdown, Form, Button } from 'react-bootstrap';
import {
    setSolventsAction, setSolventsType, setShowSolventsModal, setSolventsString,
    setReagentsString, setReagentsType, setReagentsAction, setShowReagentsModal,
    setReactantsAction, setShowReactantsModal, setReactantsString,
    setProductsAction, setShowProductsModal, setProductsString,
    setSimilarityThresh, setMoleculesString, setReactionsString,
    setSetYield, setHeuYieldSteps, setHeuYieldThresh,
    setSetSRI, setHeuSRIThresh, setHeuSRISteps, setRankOn
} from './reducer';


const Params = () => {
    const dispatch = useDispatch();
    const step1Params = useSelector((state) => state.synthetic.step1Params);

    const onSolventsChange = (event) => {
        var vaule = event.target.value;
        dispatch(setSolventsString(vaule));
    }

    const onReagentsChange = (event) => dispatch(setReagentsString(event.target.value));

    const onReactantsChange = (event) => {
        var vaule = event.target.value;
        dispatch(setReactantsString(vaule));
    }

    const onProductsChange = (event) => {
        var vaule = event.target.value;
        dispatch(setProductsString(vaule));
    }

    const onSimilarityChange = (event) => {
        var steps = event.target.value;
        dispatch(setSimilarityThresh(steps));
    }

    const onMoleculesChange = (event) => {
        var steps = event.target.value;
        dispatch(setMoleculesString(steps));
    }

    const onReactionsChange = (event) => {
        var steps = event.target.value;
        dispatch(setReactionsString(steps));
    }

    const onYieldThreshChange = (event) => {
        var vaule = event.target.value;
        dispatch(setHeuYieldThresh(vaule));
    }

    const onYieldStepsChange = (event) => {
        var vaule = event.target.value;
        dispatch(setHeuYieldSteps(vaule));
    }

    const onSRIThreshChange = (event) => {
        var vaule = event.target.value;
        dispatch(setHeuSRIThresh(vaule));
    }

    const onSRIStepsChange = (event) => {
        var vaule = event.target.value;
        dispatch(setHeuSRISteps(vaule));
    }

    return (
        <>
            <div className='title'>
                Set Preference on Solvents
            </div>
            <div className='solvents'>
                <span>To</span>
                <DropdownButton title={step1Params.solvents_trim.action} className='solvents-action'>
                    <Dropdown.Item onClick={() => dispatch(setSolventsAction('remove'))}>remove</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setSolventsAction('keep'))}>keep</Dropdown.Item>
                </DropdownButton>
                <span>
                    reactions using solvents that contain
                </span>
                <DropdownButton title={step1Params.solvents_trim.type} className='solvents-type'>
                    <Dropdown.Item onClick={() => dispatch(setSolventsType('halogens'))}>halogens</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setSolventsType('benzene ring'))}>benzene ring</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setSolventsType('others'))}>others</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setSolventsType('none'))}>none</Dropdown.Item>
                </DropdownButton>
                {step1Params.solvents_trim.type === 'others' &&
                    <div className='others'>
                        <span>Please provide molecule IDs (separate by ','):</span>
                        <Form.Control type="text" value={step1Params.solvents_trim.solvents_string} onChange={onSolventsChange} className='solvents-string' />
                        <div style={{ marginTop: 10 + 'px' }}>
                            <span>To find molecule IDs based on molecular drawing, click here: </span>
                            <Button onClick={() => dispatch(setShowSolventsModal(true))} variant="primary" size="sm"
                                className='select-target'>
                                Get Molecule IDs
                            </Button>
                        </div>
                    </div>
                }
            </div>
            <hr style={{ marginTop: 30 + 'px' }} />
            <div className='title'>
                Set Preference on Reagents
            </div>
            <div className='reagents'>
                <span>To</span>
                <DropdownButton title={step1Params.reagents_trim.action} className='reagents-action'>
                    <Dropdown.Item onClick={() => dispatch(setReagentsAction('remove'))}>remove</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setReagentsAction('keep'))}>keep</Dropdown.Item>
                </DropdownButton>
                <span>
                    reactions using reagents that contain
                </span>
                <DropdownButton title={step1Params.reagents_trim.type} className='reagents-type'>
                    <Dropdown.Item onClick={() => dispatch(setReagentsType('halogens'))}>halogens</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setReagentsType('benzene ring'))}>benzene ring</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setReagentsType('others'))}>others</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setReagentsType('none'))}>none</Dropdown.Item>
                </DropdownButton>
                {step1Params.reagents_trim.type === 'others' &&
                    <div className='others'>
                        <span>Please provide molecule IDs (separate by ','):</span>
                        <Form.Control type="text" value={step1Params.reagents_trim.reagents_string} onChange={onReagentsChange} className='reagents-string' />
                        <div style={{ marginTop: 10 + 'px' }}>
                            <span>To find molecule IDs based on molecular drawing, click here: </span>
                            <Button onClick={() => dispatch(setShowReagentsModal(true))} variant="primary" size="sm"
                                className='select-target'>
                                Get Molecule IDs
                            </Button>
                        </div>
                    </div>
                }
            </div>
            <hr style={{ marginTop: 30 + 'px' }} />
            <div className='title'>
                Set Preference on Reactants and Products
            </div>
            <div className='description'>
                You can “keep” or “remove” reactions involving in any preferred or undesired reactants or products by providing their molecule IDs. Click “Get Molecule IDs” to get IDs by drawing molecules.
            </div>
            <div className='set-reatants-products'>
                <div className='set-reactants'>
                    <span>To</span>
                    <DropdownButton title={step1Params.reactants_action} className='reactants-action'>
                        <Dropdown.Item onClick={() => dispatch(setReactantsAction('remove'))}>remove</Dropdown.Item>
                        <Dropdown.Item onClick={() => dispatch(setReactantsAction('keep'))}>keep</Dropdown.Item>
                    </DropdownButton>
                    <span>
                        reactions contain reactants (separate by ','):
                    </span>
                    <Form.Control type="text" value={step1Params.reactants_string} onChange={onReactantsChange} className='reactants-string' />
                    <Button onClick={() => dispatch(setShowReactantsModal(true))} variant="primary" size="sm"
                        className='select-target' style={{ marginLeft: 5 + 'px' }}>
                        Get Molecule IDs
                    </Button>
                </div>
                <div className='set-products'>
                    <span>To</span>
                    <DropdownButton title={step1Params.products_action} className='products-action'>
                        <Dropdown.Item onClick={() => dispatch(setProductsAction('remove'))}>remove</Dropdown.Item>
                        <Dropdown.Item onClick={() => dispatch(setProductsAction('keep'))}>keep</Dropdown.Item>
                    </DropdownButton>
                    <span>
                        reactions contain products (separate by ','):
                    </span>
                    <Form.Control type="text" value={step1Params.products_string} onChange={onProductsChange} className='products-string' />
                    <Button onClick={() => dispatch(setShowProductsModal(true))} variant="primary" size="sm"
                        className='select-target' style={{ marginLeft: 5 + 'px' }}>
                        Get Molecule IDs
                    </Button>
                </div>
            </div>
            <hr style={{ marginTop: 30 + 'px' }} />
            <div className='title'>
                Set threshold of similarity between reactant and product
            </div>
            <div className='description'>
                You can remove reactions that main reactant and product are less similar, because low similarity between reactant and product suggesting big changes in molecular structure which may not be favored. For example, a combustion reaction of alkanes to CO2.
            </div>
            <div className='similarity-thres'>
                <span>If similarity between reactant and product less than </span>
                <Form.Control type="number" value={step1Params.similarity.thresh} onChange={onSimilarityChange} className='similarity-thres-value' />
                <span> (range 0 to 1),  the reaction will be removed.</span>
            </div>
            <hr style={{ marginTop: 30 + 'px' }} />
            <div className='title'>
                Specify molecules and reactions to remove from the network
            </div>
            <div className='description'>
                These information are provided after the first round of search, when you know which molecules and/or reactions are undesired by examining the resulted synthetic routes/network. Just copy IDs here and rerun the searching from this step.
            </div>
            <div className='molecules'>
                <span>IDs of molecules to remove (separate by ','):</span>
                <Form.Control type="text" value={step1Params.remove_molecules_string} onChange={onMoleculesChange} className='molecules-string' />
            </div>
            <div className='reactions'>
                <span>IDs of reactions to remove (separate by ','):</span>
                <Form.Control type="text" value={step1Params.reactions_string} onChange={onReactionsChange} className='reactions-string' />
            </div>
            <hr style={{ marginTop: 30 + 'px' }} />
            <div className='title'>
                Set yield-based heuristic to filter routes
            </div>
            <div className='rank-routes'>
                <span>Do you want to set yield-based heuristic :</span>
                <DropdownButton title={step1Params.set_yield} className='rank-on'>
                    <Dropdown.Item onClick={() => dispatch(setSetYield('Yes'))}>Yes</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setSetYield('No'))}>No</Dropdown.Item>
                </DropdownButton>
            </div>
            {step1Params.set_yield === "Yes" &&
                <div>
                    <div className='yield-thresh'>
                        <span>Yield of each reaction step in a preferred synthetic route should not less than</span>
                        <Form.Control type="number" value={step1Params.heu_route_yield.thresh} onChange={onYieldThreshChange} className='yield-thresh-value' />
                        <span>%;</span>
                    </div>
                    <div className='yield-steps'>
                        <span>While allow</span>
                        <Form.Control type="number" value={step1Params.heu_route_yield.n_steps_fail} onChange={onYieldStepsChange} className='yield-steps-value' />
                        <span>reactions with yield lower than above threshold.</span>
                    </div>
                    <div className='description'>
                        This number range between 0 and number of synthetic steps. Suggest starting from 10.0% and 0 allowed reaction, see how many routes can be found. Relax these parameters (e.g., lower yield threshold) if only a few of routes can be found.
                    </div>
                </div>
            }
            <hr style={{ marginTop: 30 + 'px' }} />
            <div className='title'>
                Set Similarity-based heuristic to filter routes
            </div>
            <div className='rank-routes'>
                <span>Do you want to set similarity-based heuristic :</span>
                <DropdownButton title={step1Params.set_SRI} className='rank-on'>
                    <Dropdown.Item onClick={() => dispatch(setSetSRI('Yes'))}>Yes</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setSetSRI('No'))}>No</Dropdown.Item>
                </DropdownButton>
            </div>
            {step1Params.set_SRI === "Yes" &&
                <div>
                    <div className='sri-thresh'>
                        <span>Similarity of each reaction step in a preferred synthetic route should not less than</span>
                        <Form.Control type="number" value={step1Params.heu_route_SRI.thresh} onChange={onSRIThreshChange} className='sri-thresh-value' />
                        <span>(range between 0 and 1) ;</span>
                    </div>
                    <div className='sri-steps'>
                        <span>While allow</span>
                        <Form.Control type="number" value={step1Params.heu_route_SRI.n_steps_fail} onChange={onSRIStepsChange} className='sri-steps-value' />
                        <span>reactions with similarity lower than above threshold.</span>
                    </div>
                    <div className='description'>
                        This number range between 0 and number of synthetic steps. Suggest starting from 0.8 and 0 allowed reaction, see how many routes can be found. Relax these parameters (e.g., lower similarity threshold) if only a few of routes can be found.
                    </div>
                </div>
            }
            <hr style={{ marginTop: 30 + 'px' }} />
            <div className='title'>
                Set ranking method
            </div>
            <div className='rank-routes'>
                <span>Heuristic used for rank synthetic routes:</span>
                <DropdownButton title={step1Params.rank_on} className='rank-on'>
                    <Dropdown.Item onClick={() => dispatch(setRankOn('Similarity'))}>Similarity</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setRankOn('Yield'))}>Yield</Dropdown.Item>
                </DropdownButton>
            </div>
        </>
    );
};

export default function Advanced() {
    return (
        <div className='advanced'>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Advanced Settings</Accordion.Header>
                    <Accordion.Body>
                        <Params />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}