import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col, Form, DropdownButton, Dropdown, Spinner } from 'react-bootstrap';
import {
    setShowMoleModal, setMoleType, setTopNMole, setLoading, setShowMoleResult, setMoleIndeces,
    setMoleIDs
} from '../reducer';
import { Show_Compounds, Show_Mole_By_Id, Show_Mole_By_Index } from '../../constant';
import { postData } from '../../apiHelper';


function Params() {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.synthetic.isLoading);
    const step2Params = useSelector((state) => state.synthetic.step2Params);
    const showMoleResult = useSelector((state) => state.synthetic.showMoleResult);

    const onTopNChange = (event) => {
        var value = event.target.value;
        dispatch(setTopNMole(value));
    }

    const onMoleIndecesChange = (event) => {
        var value = event.target.value;
        dispatch(setMoleIndeces(value));
    }

    const onMoleIDsChange = (event) => {
        var value = event.target.value;
        dispatch(setMoleIDs(value));
    }

    const showMolecules = () => {
        if (step2Params.mole_type === "By indices") {
            if (step2Params.mole_indices.replace(/\s+/g, '') === "") {
                alert("Please provide molecule IDs in the network.");
            } else {
                dispatch(setLoading(true));
                dispatch(setShowMoleResult(null));

                postData(Show_Mole_By_Index, {
                    show_all_comps: false,
                    sel_comps_idxs: step2Params.mole_indices.replace(/\s+/g, '').split(',').map(Number)
                }).then(
                    (result) => {
                        if (result && result.message && result.message.includes('not exists')) {
                            alert(result.message);
                        } else if (result && result.selected_mols) {
                            dispatch(setShowMoleResult(result.selected_mols));
                        } else if (result && result.message) {
                            alert(result.message);
                        }
                        dispatch(setLoading(false));
                    },
                    (error) => {
                        console.log(error);
                        dispatch(setLoading(false));
                    }
                );
            }
        } else if (step2Params.mole_type === "By IDs") {
            if (step2Params.mole_IDs.replace(/\s+/g, '') === "") {
                alert("Please provide molecule IDs in the network.");
            } else {
                dispatch(setLoading(true));
                dispatch(setShowMoleResult(null));

                postData(Show_Mole_By_Id, {
                    compIDs: step2Params.mole_IDs.replace(/\s+/g, '').split(',')
                }).then(
                    (result) => {
                        if (result && result.message) {
                            alert(result.message);
                        } else {
                            dispatch(setShowMoleResult(result));
                        }
                        dispatch(setLoading(false));
                    },
                    (error) => {
                        console.log(error);
                        dispatch(setLoading(false));
                    }
                );
            }
        } else {
            if (step2Params.top_n_mole.replace(/\s+/g, '') === "") {
                alert("Please provide number of top-n molecules to display.");
            } else {
                dispatch(setLoading(true));
                dispatch(setShowMoleResult(null));

                postData(Show_Compounds, {
                    type_comps: step2Params.mole_type.toLowerCase(),
                    n_top_comps: parseInt(step2Params.top_n_mole)
                }).then(
                    (result) => {
                        if (result && result.message && result.message.includes('not exists')) {
                            alert(result.message);
                        } else if (result && result.molecules) {
                            dispatch(setShowMoleResult(result.molecules));
                        } else if (result && result.message) {
                            alert(result.message);
                        }
                        dispatch(setLoading(false));
                    },
                    (error) => {
                        console.log(error);
                        dispatch(setLoading(false));
                    }
                );
            }
        }
    }

    return (
        <div className='show-mole'>
            <div>
                <span>Select a type of molecules to show: </span>
                <DropdownButton title={step2Params.mole_type} className='mole-type'>
                    <Dropdown.Item onClick={() => dispatch(setMoleType('Targets'))}>Targets</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setMoleType('Feedstocks'))}>Feedstocks</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setMoleType('Intermediates'))}>Intermediates</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setMoleType('By indices'))}>By indices</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setMoleType('By IDs'))}>By IDs</Dropdown.Item>
                </DropdownButton>
            </div>

            <div className='description' style={{ marginTop: 20 + 'px' }}>
                Different type of molecules will be ranked by their popularities (i.e., count of occurrence in all routes.)
            </div>

            {step2Params.mole_type !== "By indices" && step2Params.mole_type !== "By IDs" &&
                <div>
                    <span>Please provide number of top-n molecules to display: </span>
                    <Form.Control type="number" value={step2Params.top_n_mole} onChange={onTopNChange} className='top-n' />
                </div>
            }
            {step2Params.mole_type === "By indices" &&
                <div>
                    <span>Please provide molecule indices in the network (separate by ','): </span>
                    <Form.Control type="text" value={step2Params.mole_indices} onChange={onMoleIndecesChange} className='molecules-string' />
                </div>
            }
            {step2Params.mole_type === "By IDs" &&
                <div>
                    <span>Please provide molecule IDs in the network (separate by ','): </span>
                    <Form.Control type="text" value={step2Params.mole_IDs} onChange={onMoleIDsChange} className='molecules-string' />
                </div>
            }

            <div style={{ marginTop: 20 + 'px' }}>
                <Button onClick={showMolecules} variant="primary" disabled={isLoading}>
                    {isLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: 5 + 'px' }}
                        />
                    }
                    Show Molecules
                </Button>
            </div>
            {showMoleResult &&
                <div style={{ marginTop: 20 + 'px' }}>
                    <Row>
                        {Object.entries(showMoleResult).map(([key, value]) =>
                            <Col xs={3} key={key} className="show-mole-item">
                                <img src={"data:image/png;base64," + value} alt="" />
                                <div>
                                    {key}
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            }
        </div >
    );
}

function Footer() {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.synthetic.isLoading);
    const handleClose = () => dispatch(setShowMoleModal(false));

    return (
        <div className='footer'>
            <hr style={{ marginBottom: 30 + 'px' }} />
            <Button variant="secondary" onClick={handleClose} style={{ marginRight: 20 + 'px' }} disabled={isLoading}>
                Close
            </Button>
        </div>
    );
}


export default function MoleModal() {
    return (
        <div>
            <div className="fade modal-backdrop show"></div>
            <div className='cdi-modal'>
                <div className='cdi-modal-dialog frag'>
                    <div className='cdi-modal-content'>
                        <div className='cdi-modal-title'>
                            View Molecules
                        </div>
                        <hr style={{ marginBottom: 30 + 'px' }} />
                        <Params />
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}