import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { setShowSummaryModal } from '../reducer';


export default function SummaryModal() {
    const step2Routes = useSelector((state) => state.synthetic.step2Routes);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.synthetic.isLoading);
    const handleClose = () => dispatch(setShowSummaryModal(false));

    const download = () => {
        // create file in browser
        const fileName = "routes-summary";
        const json = JSON.stringify(step2Routes, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    return (
        <div>
            <div className="fade modal-backdrop show"></div>
            <div className='cdi-modal'>
                <div className='cdi-modal-dialog frag'>
                    <div className='cdi-modal-content'>
                        <div className='cdi-modal-title'>
                            View summary
                        </div>
                        <hr style={{ marginBottom: 30 + 'px' }} />
                        <div className='view-summary'>
                            <Button variant="primary" onClick={download} style={{ marginBottom: 20 + 'px' }} >
                                Download
                            </Button>
                            <Button variant="secondary" onClick={handleClose} style={{ marginLeft: 20 + 'px', marginBottom: 20 + 'px' }} disabled={isLoading}>
                                Close
                            </Button>
                            <div>
                                <pre>{JSON.stringify(step2Routes.report_data, null, 2)}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}