import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import './header.css'

const header = () => {
    const pathname = window.location.pathname;

    return (
        <Navbar bg="none" expand="lg" className='header container'>
            <Navbar.Brand className='brand'>CDI</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="menu">
                    <Nav.Link onClick={() => window.open('/synthetic')} className={`${ pathname === '/synthetic' ? "active": ""}`}>Search Synthetic Routes</Nav.Link>
                    <Nav.Link onClick={() => window.open('/analogue')} className={`${ pathname === '/analogue' ? "active": ""}`}>Search Analogue Routes</Nav.Link>
                    <Nav.Link onClick={() => window.open('/chiral')} className={`${ pathname === '/chiral' ? "active": ""}`}>Search Chiral Reactions</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default header