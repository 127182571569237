import { useSelector, useDispatch } from 'react-redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { setSetSearchMethod, setSetSearchSpeed } from './reducer';


export default function Long() {
    const dispatch = useDispatch();
    const searchMethods = [1, 2];
    const step1Params = useSelector((state) => state.synthetic.step1Params);

    return (
        <div className="long-params">
            <div className='title'>
                Additional parameter for long-range search:
            </div>
            <div className='search-method'>
                Search method:
                <DropdownButton title={step1Params.search_method}>
                    {searchMethods.map((n) =>
                        <Dropdown.Item onClick={() => dispatch(setSetSearchMethod(n))} key={n}>
                            {n}
                        </Dropdown.Item>
                    )}
                </DropdownButton>
            </div>
            <div className='search-speed'>
                Search speed:
                <DropdownButton title={step1Params.search_speed}>
                    <Dropdown.Item onClick={() => dispatch(setSetSearchSpeed("fast"))}>
                        fast
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(setSetSearchSpeed("slow"))}>
                        slow
                    </Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    );
}