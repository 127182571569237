import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (state) => {
    let useridString = localStorage.getItem("userid");
    if (useridString) {
        localStorage.setItem(useridString + "-analogue-params", JSON.stringify({
            isLoading: state.isLoading,
            currentStep: state.currentStep,
            searchStatus: state.searchStatus,
            searchId: state.searchId,
            showSettingModal: state.showSettingModal,
            existingSettings: state.existingSettings,
            step1Params: state.step1Params,
            step1Result: state.step1Result,
            step2Params: state.step2Params,
            showMoleResult: state.showMoleResult
        }));
    }
}

const initialState = {
    isLoading: false,
    currentStep: 1,
    searchStatus: "Not Started",
    searchId: "",
    showSettingModal: false,
    existingSettings: null,
    step1Params: {
        rxn_steps: 1,
        explicit_H: "True",
        chirality: "False",
        atom_in_ring: "False",
        feed_mol: null,
        feed_img_reference: null,
        feed_img_changed: null,
        feed_img_unchanged: null,
        feed_bond_ref: null,
        feed_branches_changed: null,
        feed_branches_unchanged: null,
        target_mol: null,
        target_img_reference: null,
        target_img_changed: null,
        target_img_unchanged: null,
        target_bond_ref: null,
        target_branches_changed: null,
        target_branches_unchanged: null,
        sim_thresh: "0.5",
    },
    step1Result: null,
    step2Params: {
        path_n: 2,
        length_options: [],
        reaction_per_page: 10,
        showMoleModal: false,
        mole_IDs: ""
    },
    step2Routes: null,
    showMoleResult: null,
    step2ReactionIds: "",
    step2ReactionDetails: null,
    showSummaryModal: false
};

export const analogueSlice = createSlice({
    name: 'analogue',
    initialState,
    reducers: {
        setState: (state, action) => {
            state.isLoading = action.payload.isLoading;
            state.currentStep = action.payload.currentStep;
            state.searchStatus = action.payload.searchStatus;
            state.searchId = action.payload.searchId;
            state.showSettingModal = action.payload.showSettingModal;
            state.existingSettings = action.payload.existingSettings;
            state.step1Params = action.payload.step1Params;
            state.step1Result = action.payload.step1Result;
            state.step2Params = action.payload.step2Params;
            state.step2Routes = action.payload.step2Routes;
            state.showMoleResult = action.payload.showMoleResult;
        },
        setShowSummaryModal: (state, action) => {
            state.showSummaryModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep2ReactionIds: (state, action) => {
            state.step2ReactionIds = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep2ReactionDetails: (state, action) => {
            state.step2ReactionDetails = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMoleIDs: (state, action) => {
            state.step2Params.mole_IDs = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowMoleModal: (state, action) => {
            state.step2Params.showMoleModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowMoleResult: (state, action) => {
            state.showMoleResult = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSearchStatus: (state, action) => {
            state.searchStatus = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSearchId: (state, action) => {
            state.searchId = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFeedBranchesChanged: (state, action) => {
            state.step1Params.feed_branches_changed = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFeedBranchesUnchanged: (state, action) => {
            state.step1Params.feed_branches_unchanged = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFeedBondRef: (state, action) => {
            state.step1Params.feed_bond_ref = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetBondRef: (state, action) => {
            state.step1Params.target_bond_ref = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetBranchesChanged: (state, action) => {
            state.step1Params.target_branches_changed = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetBranchesUnchanged: (state, action) => {
            state.step1Params.target_branches_unchanged = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setReactionPerPage: (state, action) => {
            state.step2Params.reaction_per_page = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setRoutesLength: (state, action) => {
            state.step2Params.path_n = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setRoutesLengthOptions: (state, action) => {
            state.step2Params.length_options = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep2Routes: (state, action) => {
            state.step2Routes = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setExistingSettings: (state, action) => {
            state.existingSettings = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowSettingModal: (state, action) => {
            state.showSettingModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setExplicitH: (state, action) => {
            state.step1Params.explicit_H = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setCheckStereo: (state, action) => {
            state.step2Params.check_stereo = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setRxnSteps: (state, action) => {
            state.step1Params.rxn_steps = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep1Result: (state, action) => {
            state.step1Result = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSimThresh: (state, action) => {
            state.step1Params.sim_thresh = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFeedMol: (state, action) => {
            state.step1Params.feed_mol = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFeedImg: (state, action) => {
            state.step1Params.feed_img = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFeedReference: (state, action) => {
            state.step1Params.feed_img_reference = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFeedChanged: (state, action) => {
            state.step1Params.feed_img_changed = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setFeedUnchanged: (state, action) => {
            state.step1Params.feed_img_unchanged = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetMol: (state, action) => {
            state.step1Params.target_mol = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetReference: (state, action) => {
            state.step1Params.target_img_reference = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetChanged: (state, action) => {
            state.step1Params.target_img_changed = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetUnchanged: (state, action) => {
            state.step1Params.target_img_unchanged = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setChirality: (state, action) => {
            state.step1Params.chirality = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setAtomInRing: (state, action) => {
            state.step1Params.atom_in_ring = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep: (state, action) => {
            state.currentStep = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        resetState: () => {
            // update localStorage
            updateLocalStorage(initialState);

            return initialState;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setState, setLoading, setStep, setStep1Result, setFeedChanged, setFeedUnchanged, setChirality, setFeedReference,
    setFeedMol, setTargetMol, setTargetChanged, setTargetUnchanged, setSimThresh, setStep2Result, setCheckStereo,
    setRxnSteps, setExplicitH, setTargetReference, setExistingSettings, setShowSettingModal, setStep2Routes, setShowSummaryModal,
    setRoutesLength, setRoutesLengthOptions, resetState, setSearchStatus, setAtomInRing, setShowMoleModal, setStep2ReactionDetails,
    setReactionPerPage, setFeedBondRef, setTargetBondRef, setSearchId, setMoleIDs, setShowMoleResult, setStep2ReactionIds,
    setFeedBranchesChanged, setFeedBranchesUnchanged, setTargetBranchesChanged, setTargetBranchesUnchanged
} = analogueSlice.actions;

export default analogueSlice.reducer;