import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: null
    },
    reducers: {
        setUser: (state, action) => {
            state.currentUser = action.payload;

            if(action.payload && action.payload.sub){
                localStorage.setItem("userid", action.payload.sub);
            }
        }
    }
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;